<script>
import { findIndex } from 'lodash';
import silenceSrc from '../500-milliseconds-of-silence.mp3';

export default {
	name: 'MediaManager',
	inject: ['$media', '$mediaAudio'],
	data() {
		return {
			media: {},
			currentlyPlaying: undefined,
			isPlayingQueue: false,
			queue: [],
			playTimeout: undefined,
			silenceSrc,
			didStopWhilePlayingQueue: false
		};
	},
	created() {
		// initiate audio playback on user interaction
		document.addEventListener('click', this.handleUserInteraction, { once: true, capture: true });
		window.addEventListener('blur', this.handleWindowBlur);
		window.addEventListener('focus', this.handleWindowFocus);

		this.$media.manager.on('register', (media) => {
			this.media[media.name] = media;
			if (media.type == 'audio' && media.preload) new Audio(media.src);
			media.$emit('registered');
		});
		this.$media.manager.on('unregister', (media) => {
			// in case media is unregistered as it is still playing, we need to clear currentlyPlaying
			if (this.currentlyPlaying && this.currentlyPlaying.name == media.name) {
				this.currentlyPlaying = undefined;
			}
			delete this.media[media.name];
		});
		this.$media.manager.on('play', ({ name, options = {} }) => {
			this.play(name, options);
		});
		this.$media.manager.on('playQueue', () => {
			this.playQueue();
		});
		this.$media.manager.on('pause', ({ name }) => {
			this.media[name].pause();
		});
		this.$media.manager.on('stop', ({ name }) => {
			this.stop(name);
		});
		this.$media.manager.on('stopMedia', () => {
			if (this.playTimeout) {
				clearTimeout(this.playTimeout);
				this.playTimeout = undefined;
			}

			if (this.currentlyPlaying) {
				this.stop(this.currentlyPlaying.name);
			}

			// clear queue if flow was stopped while the queue was playing or if is currently playing queue
			// 'stopMedia' is triggered in various places in the app:
			// one of them is when recording starts in the AsrRecordButton
			// another place is when playing a recording in AsrPlayButton
			// along with all the other flows of switching exercise, switching steps, interrupting media, etc
			// if we're just starting to recording for Asr or playing a recording, we don't necessarily need to clear the queue
			if (this.didStopWhilePlayingQueue || this.isPlayingQueue) {
				this.queue = [];
			}
		});
		this.$media.manager.on('addToQueue', ({ name, options = {} }) => {
			// objects stored in queue contain both the name and options
			this.queue.push({ name, ...options });
			this.media[name].$emit('queued');
		});
		this.$media.manager.on('removeFromQueue', ({ name }) => {
			const index = findIndex(this.queue, ['name', name]);
			if (index > -1) {
				delete this.queue[index];
				this.media[name].$emit('unqueued');
			}
		});
	},
	beforeUnmount() {
		document.removeEventListener('click', this.handleUserInteraction);
	},
	methods: {
		handleUserInteraction() {
			if (!this.$media.hasUserInteracted) {
				this.$media.hasUserInteracted = true;
				this.$mediaAudio.get().src = this.silenceSrc;
				this.$mediaAudio.get().play();
			}
		},
		handleEndEvent() {
			if (this.didStopWhilePlayingQueue) {
				this.isPlayingQueue = false;
				this.queue = [];
			}
			if (this.currentlyPlaying) {
				if (this.currentlyPlaying.options.onEnd) this.currentlyPlaying.options.onEnd();
				if (this.currentlyPlaying.media) this.currentlyPlaying.media.$emit('unqueued');
			}
			this.reset();
			if (this.isPlayingQueue) this.playQueue();
		},
		play(name, options, isPlayingQueue = false) {
			this.didStopWhilePlayingQueue = false;
			// only attempt to play if user has interacted
			if (this.$media.hasUserInteracted) {
				if (this.currentlyPlaying) {
					this.stop(this.currentlyPlaying.name);
				}

				this.currentlyPlaying = { name, media: this.media[name], options };

				if (options.delay) {
					this.playTimeout = setTimeout(() => {
						if (isPlayingQueue) this.isPlayingQueue = true;
						this.media[name].play().then((el) => {
							el.addEventListener('ended', this.handleEndEvent, { once: true });
						});
					}, options.delay);
				} else {
					if (isPlayingQueue) this.isPlayingQueue = true;
					this.media[name].play().then((el) => {
						el.addEventListener('ended', this.handleEndEvent, { once: true });
					});
				}
			} else {
				console.error('Can\'t play "' + name + '". user has not yet interacted with page');
			}
		},
		playQueue() {
			this.didStopWhilePlayingQueue = false;
			if (this.queue.length == 0) {
				this.isPlayingQueue = false;
				return;
			}

			// objects stored in queue contain both the name and options
			const media = this.queue.shift();
			const { name, ...options } = media;
			this.play(name, options, true);
		},
		stop(name) {
			this.didStopWhilePlayingQueue = this.isPlayingQueue;
			this.media[name].stop();
			this.reset();
		},

		reset() {
			this.currentlyPlaying = undefined;
			if (this.playTimeout) this.playTimeout = undefined;
		},
		handleWindowBlur() {
			if (this.currentlyPlaying) {
				this.currentlyPlaying.media.pause();
			}
		},
		handleWindowFocus() {
			if (this.currentlyPlaying) {
				this.currentlyPlaying.media.play();
			}
		}
	},
	render() {
		return null;
	}
};
</script>

<style></style>
