<script>
export default {
	name: 'BaseIcon',
	props: {
		icon: {
			type: String,
			required: true
		},
		defs: {
			type: String,
			required: true
		}
	}
};
</script>

<template>
	<div :class="['tactus-icon', 'icon-' + icon]">
		<img class="size-placeholder" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" />
		<svg><use :href="defs + '#icon-' + icon"></use></svg>
	</div>
</template>

<style lang="scss">
.tactus-icon {
	position: relative;
	display: inline-block;
	font-size: inherit;
	line-height: 1;
	height: 1em;

	.size-placeholder {
		// do not allow the placeholder image to be selected, i.e. don't display the 'save image' popup
		pointer-events: none;
		display: block;
		height: 100%;
	}

	svg {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		stroke-width: 0;
		stroke: currentColor;
		fill: currentColor;
	}
}
.icon-first-letter,
.icon-final-sound,
.icon-rhyme,
.icon-same-start,
.icon-starting-sound,
.icon-first-sound,
.icon-syllable,
.icon-written-word {
	.size-placeholder {
		height: 200%;
	}
}
</style>
