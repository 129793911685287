<template>
	<p>
		Your speech therapist has made a change to your profile.<br />
		As a result, you can <strong>no longer access homework</strong>.<br />
		If you were not expecting this change, please ask your therapist.
	</p>
	<p>
		If you want to continue practicing, check out the
		<a class="apps-link" href="https://tactustherapy.com/home-use/" target="_blank">Tactus Therapy mobile apps</a>.
	</p>
</template>

<script>
export default {
	name: 'PatientDischargedMessage'
};
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';

.apps-link {
	text-decoration: underline;
	&:hover {
		color: $secondary-1;
	}
}
</style>
