import { defineAsyncComponent } from 'vue';
import mitt from 'mitt';

const PLUGIN_NAME = 'PortalsPlugin';
const VERSION = '1.0.0';

const DEFAULT_OPTIONS = {};

const PortalsPlugin = {
	install(app, userOptions) {
		// eslint-disable-next-line no-unused-vars
		let options = { ...DEFAULT_OPTIONS, ...userOptions };

		// set up plugins registry if it doesn't exist
		if (typeof app.config.globalProperties.$plugins == 'undefined') {
			app.config.globalProperties.$plugins = {};
		}
		// register plugin with plugins registry
		app.config.globalProperties.$plugins[PLUGIN_NAME] = VERSION;

		// register 'Portal' component globally
		app.component(
			'Portal',
			defineAsyncComponent(() => import('./components/Portal.vue'))
		);
		// register 'PortalTarget' component globally
		app.component(
			'PortalTarget',
			defineAsyncComponent(() => import('./components/PortalTarget.vue'))
		);
		const eventBus = mitt();
		const portals = [];

		eventBus.on('register', (portal) => {
			portals.push(portal);
			eventBus.emit('ready', portal);
		});
		eventBus.on('check', (portal) => {
			const portalReady = portals.includes(portal);
			if (portalReady) eventBus.emit('ready', portal);
		});

		app.provide('$portalRegistry', eventBus);
	}
};
export { PortalsPlugin as default };
