<script>
export default {
	name: 'BaseButton',
	props: {
		color: {
			type: String,
			default: 'default'
		},
		size: {
			type: String,
			default: 'md',
			validator(val) {
				return ['sm', 'md', 'lg', 'xl'].includes(val);
			}
		},
		btnStyle: {
			type: String,
			default: 'solid',
			validator(val) {
				return ['solid', 'outline', 'link'].includes(val);
			}
		},
		width: {
			type: String,
			default: 'auto',
			validator(val) {
				return ['auto', 'full'].includes(val);
			}
		},
		hasShadow: {
			type: Boolean,
			default: false
		},
		isDisabled: {
			type: Boolean,
			default: false
		},
		to: {
			type: String,
			default: undefined
		}
	},
	computed: {
		classes() {
			return [
				'basebtn',
				'basebtn-' + this.size,
				'basebtn-' + this.width,
				'basebtn-' + this.color,
				'basebtn-' + this.btnStyle,
				{ 'basebtn-shadow': this.hasShadow },
				{ disabled: this.isDisabled }
			];
		},
		isExternalLink() {
			return this.to && this.to.startsWith('http');
		}
	}
};
</script>

<template>
	<a v-if="isExternalLink" :href="to" target="_blank" :class="classes" :disabled="isDisabled"><slot></slot></a>
	<router-link v-else-if="to" :to="to" :class="classes" :disabled="isDisabled"><slot></slot></router-link>
	<button v-else type="button" :class="classes" :disabled="isDisabled">
		<slot></slot>
	</button>
</template>

<style lang="scss">
@use 'sass:map';

@import '@/assets/scss/functions.scss';
@import '@/assets/scss/variables.scss';

.basebtn {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	user-select: none;
	text-align: center;
	vertical-align: middle;
	border: rem(2) solid transparent;
	font-weight: 500;
	margin-bottom: rem(8);
	transform: none;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out, transform 0.15s ease-in-out;
	cursor: pointer;

	&:not(:last-of-type) {
		margin-right: rem(8);
	}

	&.basebtn-sm {
		padding: rem(2) rem(8);
		line-height: rem(16);
		border-radius: rem(4);
		gap: rem(4);

		.tactus-icon {
			font-size: rem(15);
		}
	}

	&.basebtn-md {
		padding: rem(5) rem(12);
		line-height: rem(18);
		border-radius: rem(4);
		gap: rem(8);

		.tactus-icon {
			font-size: rem(17.5);
		}
	}

	&.basebtn-lg {
		padding: rem(7) rem(12);
		line-height: rem(22);
		border-radius: rem(4);
		gap: rem(10);

		.tactus-icon {
			font-size: rem(20);
		}
	}

	&.basebtn-xl {
		padding: rem(10) rem(12);
		font-size: rem(18);
		line-height: rem(24);
		border-radius: rem(4);
		gap: rem(12);

		.tactus-icon {
			font-size: rem(22.5);
		}
	}

	&.basebtn-full {
		width: 100%;
	}

	&.basebtn-shadow:not(.basebtn-link) {
		transform: translateY(rem(-2));
		&:active {
			transform: translateY(rem(0));
		}
	}

	@each $name, $color in $btn-colors {
		&.basebtn-#{$name} {
			&.basebtn-solid {
				color: map.get($color, 'text');
				background-color: map.get($color, 'button');
				border-color: map.get($color, 'button');
				@media (hover: hover) {
					&:hover {
						color: mix($white, map.get($color, 'text'), 25%);
						background-color: mix($white, map.get($color, 'button'), 25%);
						border-color: mix($white, map.get($color, 'button'), 25%);
					}
				}
				&:active {
					color: mix($white, map.get($color, 'text'), 75%);
					background-color: mix($white, map.get($color, 'button'), 75%);
					border-color: mix($white, map.get($color, 'button'), 75%);
				}
			}
			&.basebtn-outline {
				color: map.get($color, 'button');
				border-color: map.get($color, 'button');
				background-color: $white;
				@media (hover: hover) {
					&:hover {
						color: mix($white, map.get($color, 'button'), 25%);
						border-color: mix($white, map.get($color, 'button'), 25%);
					}
				}
				&:active {
					color: mix($white, map.get($color, 'button'), 75%);
					border-color: mix($white, map.get($color, 'button'), 75%);
				}
			}
			&.basebtn-link {
				color: map.get($color, 'button');
				background-color: transparent;
				@media (hover: hover) {
					&:hover {
						color: mix($white, map.get($color, 'button'), 25%);
					}
				}
				&:active {
					color: mix($white, map.get($color, 'button'), 75%);
				}
			}
			&.basebtn-shadow:not(.basebtn-link) {
				box-shadow: 0 rem(2) 0 0 mix($black, map.get($color, 'button'), 25%);
				@media (hover: hover) {
					&:hover {
						box-shadow: 0 rem(2) 0 0 mix($white, mix($black, map.get($color, 'button'), 25%), 25%);
					}
				}
				&:active {
					box-shadow: 0 0 0 0 mix($white, mix($black, map.get($color, 'button'), 25%), 75%);
				}
			}
		}
	}

	&.disabled,
	&:disabled {
		color: $grey-2 !important;
		background-color: $grey-3 !important;
		border-color: $grey-3 !important;
		transform: translateY(rem(0)) !important;
		box-shadow: 0 0 0 0 mix($black, $grey-3, 25%) !important;
		cursor: default;
	}

	&.focused,
	&:focus {
		box-shadow: none;
	}
}
a.basebtn {
	text-decoration: none !important;

	&.disabled,
	&:disabled {
		pointer-events: none;
	}
}
</style>
