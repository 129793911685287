<template>
	<BaseModal
		ref="modal"
		name="re-auth"
		modal-class="re-auth"
		:show-on-load="true"
		:is-dismissible="false"
		size="sm"
		@shown="handleShown"
		@hidden="handleHidden"
	>
		<Heading :level="3">Session Expired</Heading>
		<p>Your session has expired.<br />Please enter your password to remain active.</p>
		<ReAuthForm ref="reAuthForm" :email="email" @success="$modals.hide('re-auth')" @cancel="refresh" />
	</BaseModal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal.vue';
import Heading from '@/components/Heading.vue';
import ReAuthForm from '@/plugins/Authentication/components/ReAuthForm.vue';
export default {
	name: 'ReAuthModal',
	components: {
		BaseModal,
		Heading,
		ReAuthForm
	},
	inject: ['$modals'],
	props: {
		email: {
			type: String,
			required: true
		}
	},
	emits: ['hidden'],
	methods: {
		refresh() {
			window.location.reload();
		},
		handleShown() {
			this.$refs.reAuthForm.focusFirstInput();
		},
		handleHidden() {
			window.dispatchEvent(new FocusEvent('focus'));
			this.$emit('hidden');
		}
	}
};
</script>

<style lang="scss">
#re-auth {
	backdrop-filter: blur(8px);
}
</style>
