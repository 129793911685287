export default {
	servicePath: 'users',
	modelName: 'User',
	instanceDefaults: {
		email: '',
		password: ''
	},
	setupInstance(data, { models, defineSingleAssociation }) {
		defineSingleAssociation(data, 'profile', models.api.Profile);

		return data;
	}
};
