import axios from 'axios';
import env from '@/env';

export default {
	servicePath: 'words',
	modelName: 'Word',
	actions: {
		async import({ rootState }, data, params = {}) {
			return await axios
				.post(env('API_URL') + '/words/import', data, {
					headers: {
						Authorization: 'Bearer ' + rootState.auth.accessToken,
						'Content-Type': 'application/json'
					},
					params: { ...params }
				})
				.then((response) => response.data)
				.catch((error) => {
					throw error.response.data;
				});
		}
	},
	setupInstance(data, { models, defineSingleAssociation, defineManyAssociation }) {
		defineSingleAssociation(data, 'parent', models.api.Word);
		defineSingleAssociation(data, 'defaultImage', models.api.Image);
		defineSingleAssociation(data, 'video', models.api.Video);
		defineSingleAssociation(data, 'audio', models.api.Audio);
		defineSingleAssociation(data, 'letter', models.api.Letter);
		defineManyAssociation(data, 'categories', models.api.Category);

		return data;
	}
};
