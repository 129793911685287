/**
 * This service is used for tracking app performance. For example, recording how
 * long significant actions take and reporting it to grafana.
 */

/** @typedef {{ start: Date | null, end: Date | null}} StepDuration */

import { submitPerformanceStepDuration, STEPS } from '../../observability';

export default {
	servicePath: 'performance-measurements',
	modelName: false,
	state: {
		/**
		 * @type {Record<string, StepDuration>} Object keys should be step names. Checkout
		 * object STEPS for a list of available steps.
		 */
		stepDurations: {}
	},
	actions: {
		/** @param {keyof STEPS} stepName */
		startPerformanceStep(stepName) {
			assertValidStepName(stepName);

			const step = {
				start: new Date(),
				end: null
			};

			this.stepDurations[stepName] = step;
		},
		/** @param {keyof STEPS} stepName */
		endPerformanceStep(stepName) {
			assertValidStepName(stepName);

			const step = this.stepDurations[stepName];

			if (step == null) {
				return;
			}

			step.end = new Date();

			const stepIsDone = step.start != null && step.end != null;
			if (stepIsDone) {
				const duration = step.end.getTime() - step.start.getTime();
				submitPerformanceStepDuration(stepName, duration);
				delete this.stepDurations[stepName];
			}
		},
		/** @param {keyof STEPS} stepName */
		clearPerformanceStep(stepName) {
			assertValidStepName(stepName);

			const step = this.stepDurations[stepName];

			if (step == null) {
				return;
			}

			delete this.stepDurations[stepName];
		}
	}
};

function assertValidStepName(stepName) {
	const isStepName = Object.values(STEPS).includes(stepName);
	const scopedStepNameRegex = new RegExp(`(${STEPS.GO_TO_TREATMENT_TUTORIAL}|${STEPS.GO_TO_NEXT_EXERCISE})/`);
	const isScopedStepName = scopedStepNameRegex.test(stepName);

	if (!isStepName && !isScopedStepName) {
		throw new Error(`Step ${stepName} is not a valid step name.`);
	}
}
