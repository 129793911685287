import { getServiceStore } from '@/plugins/FeathersAPI';

export default async function hasPermissions({ to, next }) {
	const user = getServiceStore('auth').user;
	const requiredPermissions = typeof to.meta.permissions == 'function' ? to.meta.permissions(to) : [];
	const activePermissions = (user && user.activePermissionsList) || [];

	const accessActive =
		requiredPermissions.length == 0 || requiredPermissions.some((permission) => activePermissions.includes(permission));

	// has access - continue
	if (accessActive) return next();

	// fetch pending and deactivated permissions
	if (typeof user.pendingLicenses == 'undefined')
		await getServiceStore('users').get(user.id, { query: { $include: ['pendingLicenses', 'deactivatedLicenses'] } });

	const pendingPermissions = user.pendingLicenses.reduce((result, license) => {
		return license.licenseType.role.permissions
			.map((p) => p.name)
			.reduce((r, p) => {
				return r.includes(p) ? result : [...result, p];
			}, result);
	}, []);
	const accessPending =
		requiredPermissions.length == 0 ||
		requiredPermissions.some((permission) => pendingPermissions.includes(permission));

	const deactivatedPermissions = user.deactivatedLicenses.reduce((result, license) => {
		return license.licenseType.role.permissions
			.map((p) => p.name)
			.reduce((r, p) => {
				return r.includes(p) ? result : [...result, p];
			}, result);
	}, []);
	const accessDeactivated =
		requiredPermissions.length == 0 ||
		requiredPermissions.some((permission) => deactivatedPermissions.includes(permission));

	// has pending access - needs payment
	if (accessPending || accessDeactivated) return next('/payment');

	// no access
	alert('Insufficient Permissions');
	return next('/404');
}
