import { getServiceStore } from '@/plugins/FeathersAPI';
import { usePluginStore } from '../../plugins/Authentication';

const requiresPayment = async ({ next }) => {
	const authPluginStore = usePluginStore();
	const user = getServiceStore('auth').user;
	let redirectPath = authPluginStore.getRoute('loginSuccess');
	if (typeof redirectPath == 'function') redirectPath = redirectPath({ user });
	return getServiceStore('users')
		.get(user.id, { query: { $include: ['pendingLicenses', 'deactivatedLicenses'] } })
		.then((result) => {
			if (
				result.pendingLicenses.length > 0 ||
				(result.deactivatedLicenses.length > 0 && result.activeLicenses.length == 0)
			)
				return next();
			else {
				return next(redirectPath);
			}
		})
		.catch(() => {
			return next(redirectPath);
		});
};

export default requiresPayment;
