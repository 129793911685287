<template>
	<BaseModal
		ref="modal"
		name="patientDischargedMessage"
		modal-class="pateint-discharged-message-modal"
		:is-dismissible="false"
		show-on-load
	>
		<div class="text-center"><Icon icon="warning-filled-circle" /></div>
		<PatientDischragedMessage />
		<div class="btn-row">
			<BaseButton width="full" color="secondary" size="lg" @click="closeModal">Ok</BaseButton>
		</div>
	</BaseModal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal.vue';
import BaseButton from '@/components/buttons/BaseButton.vue';
import Icon from '../Icon.vue';
import PatientDischragedMessage from '@/components/PatientDischargedMessage.vue';

export default {
	name: 'MaxHomeworkTreatmentsModal',
	components: {
		BaseModal,
		BaseButton,
		Icon,
		PatientDischragedMessage
	},
	inject: ['$modals'],
	methods: {
		closeModal() {
			this.$modals.hide('patientDischargedMessage');
		}
	}
};
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/functions.scss';

.pateint-discharged-message-modal {
	.modal-dialog {
		max-width: rem(560);
	}
	.icon-warning-filled-circle {
		font-size: rem(56);
		margin-bottom: rem(16);
		color: $warning;
	}
	.btn-row {
		margin-top: rem(32);
		margin-bottom: rem(-8);
	}
}
</style>
