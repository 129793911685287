export default function middlewarePipeline(context, middleware, index) {
	const nextMiddleware = middleware[index];

	if (!nextMiddleware) {
		return context.next;
	}

	return async (nextRoute) => {
		if (typeof nextRoute != 'undefined') return context.next(nextRoute);
		const nextPipeline = middlewarePipeline(context, middleware, index + 1);

		await nextMiddleware({ ...context, next: nextPipeline });
	};
}
