import moment from 'moment-timezone';

const preventMultipleActiveMaintenance = (store) => {
	return (context) => {
		if (context.data.state !== 'inactive' && store.isSet && context.id != store.currentId)
			throw new Error('Oops, looks like there is already another maintenance in "' + store.currentState + '" state');
	};
};

export default {
	servicePath: 'maintenance',
	modelName: 'Maintenance',
	customEvents: {
		updateMaintenance: (data, { store }) => {
			if (typeof data.id == 'undefined') {
				window.localStorage.removeItem('discardedMaintenanceBanner');
				store.discarded = false;
			}

			store.currentId = data.id;
			store.currentStart = data.start ? moment(data.start).tz(moment.tz.guess()) : undefined;
			store.currentEnd = data.end ? moment(data.end).tz(moment.tz.guess()) : undefined;
			store.currentState = data.state;
			store.connected = true;
		}
	},
	hooks(getServiceStore) {
		const maintenanceStore = getServiceStore('maintenance');
		return {
			before: {
				patch: [preventMultipleActiveMaintenance(maintenanceStore)],
				create: [preventMultipleActiveMaintenance(maintenanceStore)]
			}
		};
	},
	state: {
		connected: undefined,
		currentId: undefined,
		currentStart: undefined,
		currentEnd: undefined,
		currentState: 'inactive',
		discarded: false,
		bannerHeight: 0
	},
	getters: {
		isSet() {
			return this.currentState == 'notify' || this.currentState == 'active';
		},
		shouldNotify() {
			return this.currentState == 'notify';
		},
		isActive() {
			return this.currentState == 'active';
		}
	},
	actions: {
		async afterConnection() {
			if (typeof this.connected != 'undefined') return this;
			let count = 0;
			let interval;
			return new Promise((resolve, reject) => {
				interval = setInterval(() => {
					if (this.connected) resolve();
					else if (count >= 10) reject();
					count++;
				}, 100);
			})
				.catch(() => {
					console.error('Timeout while waiting for socket connection in maintenance service');
					this.connected = false;
				})
				.then(() => {
					clearInterval(interval);
					return this;
				});
		},
		checkRoute(route, user) {
			const isAuthenticated = !!user;
			const isAllowedAnonymousPage = ['/', '/login', '/404', '/maintenance'].includes(route.path);
			const hasAccess = (isAuthenticated && user.fullPermissionsList.includes('maintenance')) || isAllowedAnonymousPage;
			if (this.isActive && !hasAccess) {
				return '/maintenance';
			} else if (!this.isActive && route.path == '/maintenance') {
				return '/';
			}
			return false;
		}
	}
};
