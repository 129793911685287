import env from '@/env';
import { defineAsyncComponent, markRaw } from 'vue';
import { getServiceStore } from '@/plugins/FeathersAPI';
import axios from 'axios';

export default {
	servicePath: 'socket',
	modelName: false,
	timeout: 1000,
	state: {
		connected: false,
		pending: false,
		pendingPromise: undefined,
		waitBeforeReconnectTimer: undefined,
		waitForConnectTimer: undefined
	},
	actions: {
		// socket connection listener
		handleConnection() {
			console.log('connected', new Date().toISOString().substr(11));
			// set connected to true
			this.connected = true;
		},
		// check if a socket connection exists
		async confirmConnection() {
			// if confirmConnection is already pending
			if (this.pending) {
				console.log('return pending confirmConnection request');
				return this.pendingPromise;
			}

			console.time('confirmConnection');
			console.log('confirming connection', new Date().toISOString().substr(11));
			// set connected to false before we actually check
			this.connected = false;
			// set pending to true so we know a check is in progress
			this.pending = true;
			// set up the connection listener
			this.$feathersSocket.once('connect', this.handleConnection);

			// check the socket connection to the server
			// this has a 1 second timeout
			this.pendingPromise = this.find()
				.catch((err) => {
					console.log('error', err.message, new Date().toISOString().substr(11));
					return false;
				})
				.then((connected) => {
					console.log('confirmConnection', connected, this.connected, new Date().toISOString().substr(11));
					return new Promise((resolve, reject) => {
						// if socket is not connected, it should reconnect automatically
						if (!connected) {
							console.log('Not connected', new Date().toISOString().substr(11));
							// check if socket has already reconnected
							if (this.connected) {
								console.log('Already Reconnected');
								// resolve the confirmConnection Promise
								resolve();
								return;
							}
							// set up an interval to check the connected status
							console.time('checkConnectedStatusInterval');
							let checkConnectedStatusInterval = setInterval(() => {
								console.log('checking socket connection interval', this.connected, new Date().toISOString().substr(11));
								// if connected
								if (this.connected) {
									// resolve the confirmConnection Promise
									resolve();
									// clear this interval
									clearInterval(checkConnectedStatusInterval);
									checkConnectedStatusInterval = undefined;
									console.timeEnd('checkConnectedStatusInterval');
									// clear 1 second waitBeforeReconnect if exists
									if (this.waitBeforeReconnectTimer) {
										console.log('clear waitBeforeReconnectTimer');
										clearTimeout(this.waitBeforeReconnectTimer);
										this.waitBeforeReconnectTimer = undefined;
									}
									// clear 10 second waitForConnectTimer if exists
									if (this.waitForConnectTimer) {
										console.log('clear waitForConnectTimer');
										clearTimeout(this.waitForConnectTimer);
										this.waitForConnectTimer = undefined;
									}
									// hide the reconnecting modal if it exists
									const $modals = this._p._a._context.provides.$modals;
									$modals.hide('reconnecting');
								}
							}, 500);

							// wait for 1 sec before showing reconnecting modal
							this.waitBeforeReconnectTimer = setTimeout(() => {
								console.log('finished waitBeforeReconnectTimer');
								// if not connected
								if (!this.connected) {
									console.log('reconnecting', new Date().toISOString().substr(11));
									const $modals = this._p._a._context.provides.$modals;
									// create reconnecting modal
									$modals.create('reconnecting', {
										props: {
											showOnLoad: true,
											isDismissible: false,
											onHidden: () => {
												$modals.destroy('reconnecting');
											}
										},
										slots: {
											header: markRaw({
												template: '<Heading class="mb-0 w-100 text-center" :level="3">Lost Connection</Heading>',
												components: {
													Heading: defineAsyncComponent(() => import('@/components/Heading.vue'))
												}
											}),

											default: markRaw({
												template: `<Loader :is-visible="true" :contain-overlay="true" @load="onLoad" />`,
												components: {
													Loader: defineAsyncComponent(() => import('@/plugins/Form/components/Loader.vue'))
												},
												methods: {
													onLoad(showSuccess, showError, showLoading) {
														// if the socket doesn't reconnect within 10 seconds, reject
														this.waitForConnectTimer = setTimeout(() => {
															showError(
																markRaw({
																	template: `<div>
																Failed to reconnect.<br/>
																Please refresh your browser window or try again later.<br/><br/>
																<BaseButton class="m-auto" color="primary" @click="refresh">Refresh</BaseButton>
															</div>`,
																	components: {
																		BaseButton: defineAsyncComponent(() =>
																			import('@/components/buttons/BaseButton.vue')
																		)
																	},
																	methods: {
																		refresh() {
																			window.location.reload();
																		}
																	}
																})
															);
															clearInterval(checkConnectedStatusInterval);
															checkConnectedStatusInterval = undefined;
															console.timeEnd('checkConnectedStatusInterval');
															reject(new Error('No Socket connection'));
														}, 10000);
														showLoading(markRaw({ template: `<div>Reconnecting...</div>` }));
													}
												}
											})
										}
									});
								}
							}, 1000);
						}
						// socket is connected
						else {
							console.log('Socket still connected', new Date().toISOString().substr(11));
							// reset connected to true
							this.connected = true;
							// remove the connection listener
							this.$feathersSocket.off('connect', this.handleConnection);
							// resolve the confirmConnection Promise
							resolve();
						}
					})
						.then(() => {
							console.timeEnd('confirmConnection');
							this.pending = false;
						})
						.catch((err) => {
							console.error(err, new Date().toISOString().substr(11));
							console.timeEnd('confirmConnection');
							this.pending = false;
							const notificationsURL = env('API_URL') + '/notifications/';
							axios.post(
								notificationsURL,
								{
									type: 'debug-log-trace',
									text: 'Error - ' + err.message,
									logTrace: [...console.logTrace]
								},
								{
									headers: {
										Authorization: 'Bearer ' + getServiceStore('auth').accessToken
									}
								}
							);
							throw err;
						});
				});
			return this.pendingPromise;
		}
	}
};
