<template>
	<div id="app">
		<router-view />
		<MaintenanceBanner />
		<ReAuthModal v-if="reAuthEmail" :email="reAuthEmail" @hidden="handleReAuthHidden" />
		<OverduePaymentModal v-if="overdueLicense" />
		<PatientDischargedMessageModal v-if="showPatientDischargedMessage" @hidden="handleDischargeNotified" />
	</div>
</template>

<script>
import { getServiceStore } from '@/plugins/FeathersAPI';
import ReAuthModal from '@/components/modals/ReAuthModal.vue';
import OverduePaymentModal from '@/components/modals/OverduePaymentModal.vue';
import PatientDischargedMessageModal from '@/components/modals/PatientDischargedMessageModal.vue';
import MaintenanceBanner from '@/components/MaintenanceBanner.vue';
import onFocusMixin from '@/mixins/onFocus';

export default {
	name: 'App',
	components: {
		ReAuthModal,
		OverduePaymentModal,
		PatientDischargedMessageModal,
		MaintenanceBanner
	},
	mixins: [onFocusMixin('checkAuth')],
	inject: ['$modals'],
	data() {
		return {
			reAuthEmail: undefined,
			authStore: getServiceStore('auth'),
			showPatientDischargedMessage: false
		};
	},
	computed: {
		user() {
			return this.authStore.user;
		},
		overdueLicense() {
			return this.user ? this.user.activeLicenses.find((license) => license.state == 'overdue') : undefined;
		}
	},
	watch: {
		'authStore.isAuthenticated'() {
			this.checkPatientStatus();
		}
	},
	created() {
		this.checkPatientStatus();
	},
	methods: {
		checkAuth() {
			return this.authStore.authenticate().catch((error) => {
				if (error.code == 401 && this.authStore.user) {
					this.reAuthEmail = this.authStore.user.email;
				} else if (error.code == 408) {
					console.error('CheckAuth Timeout - ' + error.message + ' - strategy:' + error.data.strategy);
				}
				return false;
			});
		},
		checkPatientStatus() {
			const { isAuthenticated, user } = this.authStore;
			if (
				isAuthenticated &&
				user.fullPermissionsList.includes('patient') &&
				user.profile.patientStatus == 'discharged' &&
				!window.localStorage.getItem('dischargeNotified')
			)
				this.showPatientDischargedMessage = true;
		},
		handleDischargeNotified() {
			window.localStorage.setItem('dischargeNotified', true);
		},
		handleReAuthHidden() {
			this.reAuthEmail = undefined;
			this.callFocusHandlers();
		}
	}
};
</script>
<style lang="scss">
@import '@/assets/scss/colors.scss';
@import '@/assets/scss/mixins.scss';

html {
	height: 100%;
}
body {
	min-height: 100%;
	height: 100%;
	overflow-y: scroll;
	font-family: 'Poppins', Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: $text_black;
	user-select: none;

	@include textColors;
}
#app_wrapper {
	min-height: 100%;
	overflow-x: hidden;
}

#app_wrapper,
#app {
	display: flex;
	flex-direction: column;
}
#app {
	flex: 1;
}
a,
a:hover {
	color: inherit;
}
</style>
