<template>
	<transition
		:mode="mode"
		:appear="appear"
		@before-enter="beforeEnter"
		@enter="enter"
		@after-enter="afterEnter"
		@before-leave="beforeLeave"
		@leave="leave"
		@after-leave="afterLeave"
	>
		<slot></slot>
	</transition>
</template>

<script>
import { isObject, has } from 'lodash';
import Velocity from 'velocity-animate';

export default {
	name: 'Fade',
	components: {},
	props: {
		delay: {
			type: [Number, Object],
			default: 0
		},
		duration: {
			type: [Number, Object],
			default: 300
		},
		mode: {
			type: String,
			default: 'default'
		},
		appear: {
			type: Boolean,
			default: false
		}
	},
	emits: ['enter-complete', 'leave-complete'],
	data() {
		return {};
	},
	computed: {
		enterDelay() {
			return isObject(this.delay) ? (has(this.delay, 'enter') ? this.delay.enter : 0) : this.delay;
		},
		leaveDelay() {
			return isObject(this.delay) ? (has(this.delay, 'leave') ? this.delay.leave : 0) : this.delay;
		},
		enterDuration() {
			return isObject(this.duration) ? (has(this.duration, 'enter') ? this.duration.enter : 300) : this.duration;
		},
		leaveDuration() {
			return isObject(this.duration) ? (has(this.duration, 'leave') ? this.duration.leave : 300) : this.duration;
		}
	},
	methods: {
		beforeEnter(el) {
			el.style.opacity = 0;
		},
		enter(el, done) {
			setTimeout(() => {
				Velocity(el, { opacity: 1 }, { duration: this.enterDuration, complete: done });
			}, this.enterDelay);
		},
		afterEnter() {
			this.$emit('enter-complete');
		},
		beforeLeave() {},
		leave(el, done) {
			setTimeout(() => {
				Velocity(el, { opacity: 0 }, { duration: this.leaveDuration, complete: done });
			}, this.leaveDelay);
		},
		afterLeave() {
			this.$emit('leave-complete');
		}
	}
};
</script>
