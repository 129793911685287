import { getServiceStore } from '@/plugins/FeathersAPI';
import { map, findIndex, filter, isEmpty, isArray, isNull, isEqual, groupBy, mapValues } from 'lodash';
import moment from 'moment';

const numExercisesToPreGenerate = 1;
// // TODO make this a field in the database?
const numExercisesPerSet = 100;

export default {
	servicePath: 'activity-sessions',
	modelName: 'ActivitySession',
	instanceDefaults: {
		activity: null,
		activeExerciseIndex: null,
		exercises: [],
		progress: {},
		accuracy: {}
	},
	setupInstance(data, { models, defineSingleAssociation, defineManyAssociation }) {
		defineSingleAssociation(data, 'activity', models.api.Activity);
		defineSingleAssociation(data, 'profile', models.api.Profile);
		defineManyAssociation(data, 'exercises', models.api.Exercise);

		return data;
	},
	state: {
		id: undefined,
		// TODO eventually remove when always auto adjust
		autoAdjust: true,
		autoAdvancing: false,
		correctInArowToMoveUp: 3,
		incorrectInArowToMoveDown: 2,
		// do these need to be part of the state?
		correctInArow: 0,
		incorrectInArow: 0,
		nextExerciseLevelId: undefined,
		isGeneratingExercise: false,
		isSwitchingExercise: false,
		inactivityTimeoutDuration: 1000 * 60 * 60, //1 hour
		lastInteractionTime: undefined
	},
	getters: {
		activitySession() {
			return this.id ? this.getFromStore(this.id) : {};
		},
		activeExerciseIndex() {
			if (this.id) {
				const session = this.getFromStore(this.id);
				if (session) {
					return session.activeExerciseIndex;
				}
			}
			return undefined;
		},
		isFirstExercise() {
			return this.activeExerciseIndex == 0;
		},
		isLastExercise() {
			// TODO: improve to not special case? activity.data?
			// special case: managing attention and planning your tasks
			if (this.level.activityId === 35 || this.level.activityId === 36) return true;

			return this.activeExerciseIndex == numExercisesPerSet - 1;
		},
		exercises() {
			return getServiceStore('exercises').findInStore({
				query: {
					activitySessionId: this.id,
					$sort: { activitySessionOrder: 1 }
				}
			}).data;
		},
		activeExercise() {
			return this.exercises[this.activeExerciseIndex] || {};
		},
		activeExerciseActiveHints() {
			const activeExercise = this.activeExercise;
			return !isEmpty(activeExercise) &&
				activeExercise.usedHints &&
				activeExercise.usedHints.history &&
				isArray(activeExercise.usedHints.history) &&
				activeExercise.usedHints.activeIndices &&
				isArray(activeExercise.usedHints.activeIndices)
				? map(activeExercise.usedHints.activeIndices, (index) => {
						return activeExercise.usedHints.history[index];
				  })
				: [];
		},
		stimuli() {
			return this.activeExercise.data.stimuli;
		},
		hints() {
			return this.activeExercise?.data?.hints;
		},
		tiles() {
			return this.activeExercise.data.tiles;
		},
		choices() {
			return this.activeExercise.data.choices;
		},
		questions() {
			return this.activeExercise.data.questions;
		},
		isAnswerYes() {
			return this.activeExercise.data.isAnswerYes;
		},
		promptAnswers() {
			return this.activeExercise.data.promptAnswers;
		},
		bottleData() {
			return this.activeExercise.data.bottleData;
		},
		pillContainerObjectives() {
			return this.activeExercise.data.pillContainerObjectives;
		},
		level() {
			return getServiceStore('levels').getFromStore(this.activeExercise.levelId);
		},
		haveMultipleSteps() {
			// TODO: RANKING SOLUTIONS TO PROBLEMS activity setting?
			return this.level.activityId === 30;
		},
		hasUserInteracted() {
			let hasInteracted = this.activitySession.accuracy?.completed > 0;

			// check all exercises for this activity session
			for (let exercise of this.activitySession.exercises) {
				hasInteracted =
					hasInteracted ||
					exercise.completed || // repeated single syllable words
					// both exercise.answers and exercise.usedHints have default values for {} now (to fix the binary error for JSON type fields) so also need to check that they're not empty
					(exercise.answers && !isEmpty(exercise.answers)) || // pca when using at least 1 prompt
					(exercise.usedHints && !isEmpty(exercise.usedHints)); // ranking, solving picture problems, typing

				if (hasInteracted) break;
			}
			return hasInteracted;
		}
	},
	actions: {
		async initialize(payload) {
			// clear counts
			this.correctInArow = 0;
			this.incorrectInArow = 0;
			let previousActivitySession = undefined;
			let previousExercises = undefined;
			const { continueSessionId, isAsr } = payload;
			this.isAsr = isAsr;
			// if payload has continueSessionId, query that activity session so that we can use its data, exercises, activeExerciseIndex
			if (continueSessionId) {
				// instead of calling generateNewExercise, copy the exercises from the activity session that has an id of [continueSessionId]
				previousActivitySession = await this.get(continueSessionId, {
					query: { $include: ['exercises'] }
				});
				if (previousActivitySession.data) payload.data = { ...previousActivitySession.data, continueSessionId };
				previousExercises = previousActivitySession.exercises;
			}
			const activitySessionId = await this.create(payload, { query: { $include: ['activity'] } }).then(
				(result) => result.id
			);
			this.id = activitySessionId;
			this.autoAdjust = payload.autoAdjust;

			if (continueSessionId) {
				for (let i = 0; i < previousExercises.length; i++) {
					// do not duplicate these fields for the newly copied exercises: id, created at, updated at
					// eslint-disable-next-line no-unused-vars
					const { id, createdAt, updatedAt, ...restOfExercise } = previousExercises[i];
					await getServiceStore('exercises')
						.create({
							...restOfExercise,
							activitySessionId
						})
						.catch((err) => {
							console.log(`error recreating exercise with id ${id}, while continuing session: ${err}`);
						});
				}
			} else {
				for (let i = 0; i < numExercisesToPreGenerate; i++) {
					await this.generateNewExercise({
						...payload,
						activitySessionOrder: i
					}).catch((error) => {
						console.log('error', error);
						// only throw error if failed to generate first exercise
						// this way we can still display all the exercises that were generated successfully
						// before the first exercise that failed to be generated
						if (i === 0) throw error;
					});
				}
			}
			const activityTypesToNotTrackProgress = ['prompt', 'sequence'];
			// dont track progress for these activity types
			const progress = activityTypesToNotTrackProgress.includes(this.activitySession.activity.type)
				? null
				: await this.calculateProgress();
			// begin first exercise
			const activeExerciseIndex = continueSessionId ? previousActivitySession.activeExerciseIndex : 0;
			const activitySessionPatch = Object.assign({}, { activeExerciseIndex, progress });
			await this.patch(activitySessionId, activitySessionPatch, { query: { $include: ['exercises'] } });
		},
		async generateNewExercise(payload) {
			this.isGeneratingExercise = true;
			let { activeLevelId, activitySessionOrder, isLevelMaxed } = payload;
			if (isLevelMaxed) {
				const activity = await getServiceStore('activities').get(this.activitySession.activityId);
				const levels = activity.levels;
				const maxLevelOrder = Math.max(...map(levels, 'randomOrder'));
				const maxLevelIds = map(filter(levels, { randomOrder: maxLevelOrder }), 'id');
				activeLevelId = maxLevelIds[Math.floor(Math.random() * maxLevelIds.length)];
			}
			const levelQuery = { id: activeLevelId };
			const activitySessionData = this.activitySession.data;
			//console.log('activitySession data from store', activitySessionData?.subjectQuery?.category?.$in.join(','));
			await getServiceStore('exercise-generator').create({
				levelQuery,
				activitySessionId: this.id,
				activitySessionOrder,
				activitySessionData
			});
			const activitySessionId = this.id;
			const activitySessionPatch = Object.assign({}, { activeLevelId, isLevelMaxed });
			// need to include exercises in the query to ensure that the exercise object isn't overwritten
			await this.patch(activitySessionId, activitySessionPatch, { query: { $include: ['exercises'] } });
			this.nextExerciseLevelId = activeLevelId;
			this.isGeneratingExercise = false;
		},
		async pickedPlannningTasksTask(taskId) {
			await getServiceStore('exercise-generator').patch(
				{
					type: 'updateSubjectNumExerciseToExcludeFrom',
					activitySessionId: this.id,
					objectId: taskId,
					numExercisesToExcludeFrom: 20
				},
				{}
			);
		},
		async deactivateHints() {
			const exercise = this.activeExercise;
			if (exercise.completed) return;
			const usedHints = { ...exercise.usedHints };
			// TODO may need to specify which hint to deactivate. Okay for now because the play area components that use this action can't have multiple active hints
			delete usedHints['activeIndices'];
			// only need to update the hint prop, don't want to update the status and answers props
			await this.updateResults({ usedHints: JSON.stringify(usedHints) });
		},
		// The payload parameter is either (A) the object containing the new exercise patch data, or (B) a string that
		// represents the old exercise status before the present patch. In the latter case, old status is for knowing the
		// first time the user has submitted their answer. Level adjustments are made based on this.

		// Note: skipCalculateUpDownLevel is a new parameter added for ASR activities because we don't want to do the
		// calculations for moving up or down a level until the Next/Skip buttons are pressed. By default, it will be false,
		// but if you pass in true, it will skip the calculations (which can be called via a method separately)
		async updateResults(payload, skipCalculateUpDownLevel = false) {
			const exercise = this.activeExercise;

			// typeof will return 'object' for strings created with new String(), so check instanceof String as well. Also,
			// oldStatus is null in the first call to updateResults so add that to our condition.
			let hasPayload = typeof payload !== 'string' && !(payload instanceof String) && payload !== null;

			let oldStatus, newStatus;
			// patching the exercise in the local store (exercise.patch) in the following if/else statement needs to come before the check that calls this.moveUpLevel()
			// because that function (moveUpLevel) patches the activity session and includes exercises in its query. If exercise.patch is not called beforehand, the activity session
			// patch in moveUpLevel overrides the changes of modifying the exercise instance directly.
			let newExercise = null;
			if (hasPayload) {
				// If we have a payload, the exercise.status is the older status before patch. The payload contains the newStatus.
				oldStatus = exercise.status;
				newStatus = payload.status;
				// instead of directly patching server which updates local store, patch local store first which will patch server
				newExercise = exercise.patch({ data: { ...payload } });
			} else {
				// If we do not have a payload, it means the exercise instance has already been directly modified so exercise.status
				// represents the new status. And that means we passed in the old status as a parameter to this function.
				oldStatus = payload;
				newStatus = exercise.status;
				// instead of directly patching server which updates local store, patch local store first which will patch server
				newExercise = exercise.patch();
			}

			return newExercise.then(async () => {
				if (!skipCalculateUpDownLevel) {
					await this.calculateUpDownLevel(newStatus, oldStatus);
				}
				return await this.updateAccuracyAndProgressAfterPatchingExercise(newExercise);
			});
		},
		async calculateUpDownLevel(newStatus, oldStatus) {
			// if oldStatus is null, we know this is the first time updating the exercise
			if (this.autoAdjust && (oldStatus == null || oldStatus == 'incomplete')) {
				switch (newStatus) {
					case 'correct':
						this.incorrectInArow = 0;
						this.correctInArow++;
						if (this.correctInArow == this.correctInArowToMoveUp) {
							await this.moveUpLevel();
							this.correctInArow = 0;
						}
						break;
					case 'incorrect':
					case 'hint-used':
						this.correctInArow = 0;
						this.incorrectInArow++;
						if (this.incorrectInArow == this.incorrectInArowToMoveDown) {
							await this.moveDownLevel();
							this.incorrectInArow = 0;
						}
						break;
					case 'correct-partially':
						// partially correct results should not affect the correct/incorrect in a row
						break;
					default:
						break;
				}
			}
		},
		async updateAccuracyAndProgressAfterPatchingExercise(newExercise) {
			// update accuracy and progress after patching exercise

			// for some reason, setting this.activitySession to a const does not guarantee its reactivity
			// eg. change correctInArowToMoveUp to 1 and do Naming Single Nouns, if  this.activitySession is set to a const and used
			// in the switch statement on the activitySession.activity.type below, activitySession.activity is undefined
			// so we're using this.activitySession instead to ensure reactivity
			const isActivityTypeTruthy =
				this.activitySession && this.activitySession.activity && !!this.activitySession.activity.type;
			// if you refresh while doing an activity session, when you continue and try to update results, this.activitySession.activity will be truthy but an empty object
			// if this.activitySession.activity.type is not truthy then we should query the activity session with the activity included so that we can get the activity type
			if (!isActivityTypeTruthy) {
				await this.get(this.id, { query: { $include: ['activity'] } });
			}

			switch (this.activitySession.activity.type) {
				case 'matching':
				case 'yes-no':
				case 'tile':
				case 'typing':
				case 'naming':
				case 'sequence':
				case 'sorting': {
					// TODO: instead of hardcoding the activity id, have each activity define whether or not it should have accuracy/progress calculated or not
					// 'repeating single-syllable words', 'managing attention', 'planning your tasks' are sequence activities but we do not want to update accuracy or progress
					// we do want to update accuracy or progress for other sequence activities though
					// the level info on treatment cards in the treatment plan are based on the activitySession.progress field so by
					// special casing these activities, they won't display the level info on the treatment card
					if (
						this.activitySession.activityId == 28 ||
						this.activitySession.activityId == 35 ||
						this.activitySession.activityId == 36
					)
						break;

					if (this.activitySession.activityId === 37) {
						await this.calculateReadingFunctionalMaterialsAccuracy();
						break;
					}

					// handle Word-Finding differently
					// it has 3 scores per exercise (corresponding to the 3 name steps) that are reflected in the top bar
					// instead of the usual case where it's one score per exercise
					if (this.activitySession.activityId === 40) {
						await this.calculateWordFindingAccuracy();
						break;
					}

					const accuracy = await this.calculateAccuracy();
					const progress = await this.calculateProgress();
					const didAccuracyChange = !isEqual(accuracy, this.activitySession.accuracy);
					const didProgressChange = !isEqual(progress, this.activitySession.progress);
					// only patch activity session if one of accuracy or progress has actually changed
					// these values should only be changing the first time calling updateResults on a given exercise
					// this fixes a bug where the current exercise's answer.history value is overwritten
					// the following two console logs of exercises are different (the first one is more update to date)
					// console.log('exercises - direct', this.exercises[0].answers.history);
					// console.log('exercises - activity session', this.activitySession.exercises[0].answers.history);
					if (didAccuracyChange || didProgressChange) {
						let tempPatch = {};
						if (didAccuracyChange) tempPatch.accuracy = accuracy;
						if (didProgressChange) tempPatch.progress = progress;
						const patch = Object.assign({}, tempPatch);
						// need to include exercises so that exercise data (eg. exercise.answers, exercise.usedHints, etc) doesn't get overwritten
						// the real issue we need to solve is having nested associations reactive
						// an activity session has an array of exercises, each exercise has usedHints, answers, etc.
						// when we update usedHints or answers directly, we want the activity session's exercises to also update reactively, which they currently aren't
						await this.patch(this.id, patch, { query: { $include: ['exercises'] } });
					}
					break;
				}
				case 'scanning': {
					const accuracy = await this.calculateScanningAccuracy();
					const progress = await this.calculateProgress();
					const patch = Object.assign({}, { accuracy, progress });
					// this.activitySession.patch patches locally first and then pushes it to the server
					// whereas this.patch is a call to the server and then updates the local store
					// we call this.activitySession.patch specifically for scanning because when touching multiple targets very quickly, we don't want any touches to be lost or overwritten
					await this.activitySession.patch({ data: patch });
					break;
				}
				case 'prompt': {
					const accuracy = await this.calculatePromptAccuracy();
					// only update accuracy if accuracy is defined and it's different from the current accuracy (this.activitySession.accuracy)
					// the first time we update accuracy, this.activitySession.accuracy will be null
					// the first tracked interaction will be pressing a prompt Check button, this will update the accuracy from null to { correct:0, completed: 0}
					// by having accuracy as { correct:0, completed: 0}, this will display 0/0 (0%) for the accuracy on recent session/history session tables
					// the activitySession.accuracy field defaults to null, which then doesn't show anything recent session/history session tables
					// TODO: maybe default it to { correct:0, completed: 0} for activities that use accuracy?
					const shouldUpdateAccuracy = accuracy && !isEqual(accuracy, this.activitySession.accuracy);
					if (shouldUpdateAccuracy) {
						const patch = Object.assign({}, { accuracy });
						await this.patch(this.id, patch, { query: { $include: ['exercises'] } });
					}
					break;
				}
				default:
					break;
			}
			return newExercise;
		},
		// After making changes directly on the exercise instance, we call exercise.patch without needing a payload.
		// The parameter oldStatus is the previous state of the exercise status. We need this for knowing if it is the first
		// time the user has submitted their answer. Level adjustments are made based on this.
		/*async updateResultsWithoutPayload(oldStatus) {
			const exercise = this.activeExercise;

			// if oldStatus is null, we know this is the first time updating the exercise
			if (this.autoAdjust && (oldStatus == null || oldStatus == 'incomplete')) {
				// either correct or incorrect
				switch (exercise.status) {
					case 'correct':
						this.incorrectInArow = 0;
						this.correctInArow++;
						if (this.correctInArow == this.correctInArowToMoveUp) {
							await this.moveUpLevel();
							this.correctInArow = 0;
						}
						break;
					case 'incorrect':
					case 'hint-used':
						this.correctInArow = 0;
						this.incorrectInArow++;
						if (this.incorrectInArow == this.incorrectInArowToMoveDown) {
							await this.moveDownLevel();
							this.incorrectInArow = 0;
						}
						break;
					default:
						break;
				}
			}
			// instead of directly patching server which updates local store, patch local store first which will patch server
			let newExercise = await exercise.patch();

			// update accuracy and progress after patching exercise
			//const activitySession = await this.get(this.id, { query: { $include: ['activity'] } });
			const activitySession = this.activitySession;
			if (!('activity' in activitySession)) {
				await this.get(this.id, { query: { $include: ['activity'] } });
			}

			switch (activitySession.activity.type) {
				case 'matching':
				case 'yes-no':
				case 'tile':
				case 'typing':
				case 'naming': {
					const accuracy = await this.calculateAccuracy();
					const progress = await this.calculateProgress();
					const patch = Object.assign({}, { accuracy, progress });
					await this.activitySession.patch({ data: patch });

					break;
				}
				case 'scanning': {
					const accuracy = await this.calculateScanningAccuracy();
					const progress = await this.calculateProgress();
					const patch = Object.assign({}, { accuracy, progress });
					await this.activitySession.patch({ data: patch });

					break;
				}
				case 'prompt':
				case 'sequence':
				default:
					break;
			}
			return newExercise;
		},*/
		async calculateAccuracy() {
			// TODO do not paginate so we get all exercises
			const exercises = await getServiceStore('exercises').findInStore({
				query: {
					activitySessionId: this.id
				}
			}).data;
			const correct = filter(exercises, (exercise) => {
				return exercise.status == 'correct';
			}).length;
			const incorrect = filter(exercises, (exercise) => {
				return (
					exercise.status == 'incorrect' ||
					exercise.status == 'hint-used' ||
					exercise.status == 'corrected' ||
					exercise.status == 'correct-with-hint' ||
					exercise.status == 'correct-partially'
				);
			}).length;
			const completed = correct + incorrect;
			return { correct, completed };
		},
		async calculateProgress() {
			// TODO: chat to Chris above this
			// when calling 'await this.get(this.id)', this causes a watcher on the activeExercise to fire
			// which then overrides the activeExercise.answers.history array, causing the second choice selection in matching to not be tracked
			// using 'this.activitySession' instead fixes the issue
			// console.log('1', { ...this.activitySession.exercises[0].answers.history });
			// console.log('2', { ...this.exercises[0].answers.history });
			// console.log('before');
			// const activitySession = await this.get(this.id);
			const activitySession = this.activitySession;
			// console.log('after');

			// TODO: do not paginate so we get all levels
			const levelIds = await getServiceStore('levels')
				.find({
					query: { activityId: activitySession.activityId, $sort: { number: 1 } }
				})
				.then((res) => res.data.map((level) => level.id));
			const numLevelsTotal = levelIds.length;
			const currentLevelNumber = activitySession.isLevelMaxed
				? numLevelsTotal
				: // using nextExerciseLevelId because activeLevelId has not been updated yet
				  levelIds.indexOf(this.nextExerciseLevelId) + 1;
			const highestCompletedLevelNumber = activitySession.isLevelMaxed
				? numLevelsTotal
				: levelIds.indexOf(activitySession.highestCompletedLevelId) + 1;
			return { currentLevelNumber, highestCompletedLevelNumber, numLevelsTotal };
		},
		async calculateScanningAccuracy() {
			// TODO do not paginate so we get all exercises
			const exercises = await getServiceStore('exercises').findInStore({
				query: {
					activitySessionId: this.id
				}
			}).data;
			const exercisesToInclude = exercises.filter(
				(exercise) => !(isNull(exercise.status) || isNull(exercise.answers) || exercise.status === 'incomplete')
			);
			const data = {};
			for (let i = 0; i < exercisesToInclude.length; i++) {
				const exercise = exercisesToInclude[i];
				// using exercise.data.choices as we transition from the exercises choices field to have choices as a prop in the exercises data field
				const choices = exercise.data.choices;
				const fieldSize = choices.length;
				// use field size (as a number) as key
				const key = fieldSize;
				const firstSubmission =
					exercise &&
					exercise.answers &&
					exercise.answers.history &&
					isArray(exercise.answers.history) &&
					exercise.answers.history.length > 0
						? exercise.answers.history[0]
						: [];
				const correctWithHintIndices =
					exercise &&
					exercise.usedHints &&
					exercise.usedHints.correctWithHintIndices &&
					isArray(exercise.usedHints.correctWithHintIndices)
						? exercise.usedHints.correctWithHintIndices
						: [];
				const usedHintCount =
					exercise && exercise.usedHints && exercise.usedHints.usedHintCountFirstSubmission
						? exercise.usedHints.usedHintCountFirstSubmission
						: 0;
				let numTargetsFound = 0;
				const targetCharIndices = [];
				for (let i = 0; i < fieldSize; i++) {
					const char = choices[i];
					const { isTarget } = char;
					if (isTarget) {
						targetCharIndices.push(i);
						// only count targets found during the first submission
						const foundTarget = firstSubmission.includes(i);
						if (foundTarget) {
							// only count those that were found without the use of the hint
							if (!correctWithHintIndices.includes(i)) {
								numTargetsFound += 1;
							}
						}
					}
				}
				const numTargets = targetCharIndices.length;
				let nonTargetPressCount = 0;
				for (let i = 0; i < firstSubmission.length; i++) {
					const charIndex = firstSubmission[i];
					if (!targetCharIndices.includes(charIndex)) nonTargetPressCount += 1;
				}
				const start = moment(exercise.createdAt);
				const end = moment(exercise.updatedAt);
				// difference in milliseconds
				const time = end.diff(start);
				if (data[key]) {
					data[key].numTargetsFoundTotal += numTargetsFound;
					data[key].numTargetsTotal += numTargets;
					data[key].usedHintCountTotal += usedHintCount;
					data[key].numExercisesSubmitted += 1;
					data[key].time = [...data[key].time, time];
					data[key].nonTargetPressCount += nonTargetPressCount;
				} else {
					data[key] = {
						fieldSize,
						numTargetsPerExercise: numTargets,
						numExercisesSubmitted: 1,
						time: [time],
						numTargetsFoundTotal: numTargetsFound,
						numTargetsTotal: numTargets,
						usedHintCountTotal: usedHintCount,
						nonTargetPressCount
					};
				}
			}
			let numTargetsFoundOverall = 0;
			let numTargetsOverall = 0;
			const keys = Object.keys(data);
			for (let i = 0; i < keys.length; i++) {
				const key = keys[i];
				const value = data[key];
				const { numTargetsFoundTotal, numTargetsTotal } = value;
				numTargetsFoundOverall += numTargetsFoundTotal;
				numTargetsOverall += numTargetsTotal;
			}
			return { correct: numTargetsFoundOverall, completed: numTargetsOverall, grouped: data };
		},
		async calculatePromptAccuracy() {
			const exercises = await getServiceStore('exercises').findInStore({
				query: {
					activitySessionId: this.id
				},
				paginate: false
			}).data;
			// this gives us an object like: { correct: x, approximate: y, incorrect: z}
			const namePromptScoreCounts = mapValues(
				groupBy(
					// map exercise objects to their 'name' prompt score value
					// possible values: undefined, 'correct', 'approximate', 'incorrect', 'notScored'(when the name prompt check button is pressed but not scored - this is used in the report)
					map(exercises, (exercise) => {
						return exercise?.answers?.name;
					})
				),
				'length'
			);
			// for each count constant, we include the '|| 0' part for when the prop is undefined
			const correctScoreCount = namePromptScoreCounts.correct || 0;
			const approximateScoreCount = namePromptScoreCounts.approximate || 0;
			const incorrectScoreCount = namePromptScoreCounts.incorrect || 0;
			const incorrect = approximateScoreCount + incorrectScoreCount;
			const completed = correctScoreCount + incorrect;
			return { correct: correctScoreCount, completed };
		},
		async calculateReadingFunctionalMaterialsAccuracy() {
			let correct = 0;
			let completed = 0;

			const exercises = await getServiceStore('exercises').findInStore({
				query: {
					activitySessionId: this.id
				}
			}).data;
			const exercisesToInclude = exercises.filter(
				(exercise) => !(isNull(exercise.answers) || exercise.status === 'incomplete')
			);
			// for future, we should just keep track of the current correct and answered counts
			// and add to them, instead of going through all the exercises and recalculating each time
			// something is answered
			for (let i = 0; i < exercisesToInclude.length; i++) {
				const exercise = exercisesToInclude[i];

				const answers = exercise.answers;
				for (let questionKey in answers) {
					if (answers[questionKey].status === 'correct') {
						correct++;
					}
				}
				if (answers) {
					completed += Object.keys(answers).length;
				}
			}
			let accuracy = { correct, completed };

			const didAccuracyChange = !isEqual(accuracy, this.activitySession.accuracy);
			let tempPatch = {};
			if (didAccuracyChange) tempPatch.accuracy = accuracy;
			const patch = Object.assign({}, tempPatch);
			await this.patch(this.id, patch, { query: { $include: ['exercises'] } });
		},
		async calculateWordFindingAccuracy() {
			const exercises = await getServiceStore('exercises').findInStore({
				query: {
					activitySessionId: this.id
				},
				paginate: false
			}).data;

			let correct = 0;
			let incorrect = 0;
			for (const { answers } of exercises) {
				for (const stepKey in answers) {
					const isNameStep = stepKey.startsWith('name');
					if (!isNameStep) {
						continue;
					}
					if (answers[stepKey].length === 0) {
						continue;
					}
					// accuracy is based on the first entry in the answers array, so that includes:
					// first time scoring/submitting an answer
					// using the strategy buttons
					// overriding (in this case we add the override score to the first index of the answers array)
					const status = answers[stepKey][0].status;
					if (status === 'correct') {
						correct++;
					} else {
						incorrect++;
					}
				}
			}

			const completed = correct + incorrect;
			const accuracy = { correct, completed };
			const didAccuracyChange = !isEqual(accuracy, this.activitySession.accuracy);
			if (didAccuracyChange) {
				const patch = Object.assign({}, { accuracy });
				await this.patch(this.id, patch, { query: { $include: ['exercises'] } });
			}
		},
		async manuallyStartExercise(payload) {
			this.isSwitchingExercise = true;
			// if nextExerciseLevelId is defined, set it
			const { nextExerciseLevelId } = payload;
			if (nextExerciseLevelId) this.nextExerciseLevelId = nextExerciseLevelId;
			await this.goToNextExercise({ ...payload, manuallyChangeLevel: true });
		},
		async moveUpLevel() {
			const activitySession = this.activitySession;

			let levels;
			if (activitySession.selectedLevels) {
				levels = await getServiceStore('levels')
					.find({ query: { id: { $in: activitySession.selectedLevels } } })
					.then((result) => result.data);
			} else {
				const activity = await getServiceStore('activities').get(activitySession.activityId, {
					query: { $include: ['levels'] }
				});
				levels = activity.levels;
			}
			const numLevels = levels.length;
			// we should be moving up from the level of the active exercise, which isn't necessarily the level of activitySession.activeLevelId
			// eg. if you got to level 8, then backtracked to an exercise on level 5, then pressed the move up level button, we'd want to go to level 6 and not level 9
			const currentLevelId = this.activeExercise.levelId;
			const levelIndex = findIndex(levels, { id: currentLevelId });
			const patchObject = {};
			const highestCompletedLevelIndex = findIndex(levels, { id: activitySession.highestCompletedLevelId });
			if (levelIndex > highestCompletedLevelIndex) patchObject.highestCompletedLevelId = activitySession.activeLevelId;
			// any level of activity other than the last level
			if (levelIndex < numLevels - 1) {
				const nextExerciseLevelId = levels[levelIndex + 1].id;
				this.nextExerciseLevelId = nextExerciseLevelId;
			}
			// last level of activity
			else {
				const randomOrders = map(levels, 'randomOrder');
				// there are activities that don't have any random variables, and thus the randomOrder prop of their levels will be null
				const haveRandomOrder = randomOrders.some((randomOrder) => {
					return randomOrder !== null;
				});
				// only set isLevelMaxed if the activity has a random order - the flag is used to decide whether to display the last level
				// of an activity or a random level (non-random variables are set to the most diffiult setting and the random variable(s) have a random setting)
				if (haveRandomOrder && !activitySession.isLevelMaxed) {
					patchObject.isLevelMaxed = true;
				}
			}
			if (!isEmpty(patchObject)) {
				const activitySessionPatch = Object.assign({}, patchObject);
				// this.activitySession.patch patches locally first and then pushes it to the server whereas this.patch is a call to the server and then updates the local store
				return await this.patch(this.id, activitySessionPatch, { query: { $include: ['exercises'] } });
			}
			return activitySession;
		},
		async moveDownLevel() {
			const activitySession = this.activitySession;

			const activity = await getServiceStore('activities').get(activitySession.activityId, {
				query: { $include: ['levels'] }
			});
			const levels = activity.levels;
			// if we're level maxed, don't move down - just 'unmax'
			if (activitySession.isLevelMaxed) {
				const activitySessionPatch = Object.assign({}, { isLevelMaxed: false });
				// TODO: should convert to this.patch(this.id, activitySessionPatch)?
				// this.activitySession.patch patches locally first and then pushes it to the server whereas this.patch is a call to the server and then updates the local store
				await this.activitySession.patch({ data: activitySessionPatch });

				// make sure the next exercise will be the last level of the activity because when we're maxed, the activeLevelId won't necessarily be the last level
				const nextExerciseLevelId = levels[levels.length - 1].id;
				this.nextExerciseLevelId = nextExerciseLevelId;
			} else {
				// we should be moving down from the level of the active exercise, which isn't necessarily the level of activitySession.activeLevelId
				// eg. if you got to level 8, then backtracked to an exercise on level 5, then pressed the move down level button, we'd want to go to level 4 and not level 7
				const currentLevelId = this.activeExercise.levelId;
				const levelIndex = findIndex(levels, { id: currentLevelId });
				// cannot move down if we're already on the first level
				const nextExerciseLevelId = levelIndex > 0 ? levels[levelIndex - 1].id : currentLevelId;
				this.nextExerciseLevelId = nextExerciseLevelId;
			}
		},
		async goToNextExercise(payload) {
			this.isSwitchingExercise = true;
			const nextExerciseIndex = this.activeExerciseIndex + 1;
			const numExercisesGenerated = this.exercises ? this.exercises.length : 0;
			// check if we need to generate a new exercise
			const needToGenerateNewExercise =
				numExercisesGenerated < numExercisesPerSet &&
				nextExerciseIndex < numExercisesPerSet &&
				nextExerciseIndex + numExercisesToPreGenerate > numExercisesGenerated;
			const manuallyChangeLevel = payload && payload.manuallyChangeLevel ? payload.manuallyChangeLevel : false;
			if (manuallyChangeLevel) {
				this.correctInArow = 0;
				this.incorrectInArow = 0;
			}
			const shouldGenerateNewExercise = needToGenerateNewExercise || manuallyChangeLevel;
			//console.log('goToNextExercise shouldGenerateNewExercise', shouldGenerateNewExercise);
			if (shouldGenerateNewExercise) {
				const activitySession = this.activitySession;
				// default
				let isLevelMaxed = activitySession.isLevelMaxed;
				if (payload) {
					const { shouldLevelBeMaxed } = payload;
					if (typeof shouldLevelBeMaxed != 'undefined' && shouldLevelBeMaxed) {
						isLevelMaxed = shouldLevelBeMaxed;
					} else {
						isLevelMaxed = false;
					}
				}

				let activeLevelId = this.nextExerciseLevelId || activitySession.activeLevelId;
				const activitySessionOrder = numExercisesGenerated;
				await this.generateNewExercise({ activeLevelId, activitySessionOrder, isLevelMaxed }).catch((error) => {
					// only throw error when failing to generate an exercise to be immediately displayed
					// no need to throw error when failing to generate a subsequent exercise, if the exercise to be displayed was created beforehand
					if (nextExerciseIndex >= numExercisesGenerated) {
						throw error;
					}
				});
			}
			// only move to the last exercise (newly generated one) if we manually changed the level
			// eg. if we've seen 8 exercises already but back tracked to exercise 4 and then pressed the move up level button we want to make sure we then go to exercise 9
			// eg 2. if numExercisesToPreGenerate is 2, we may need to generate a new exercise but not necessary go to that newly generated exercise right now
			const activeExerciseIndex = manuallyChangeLevel ? numExercisesGenerated : nextExerciseIndex;
			const activitySessionPatch = Object.assign({}, { activeExerciseIndex });
			// this.activitySession.patch patches locally first and then pushes it to the server whereas this.patch is a call to the server and then updates the local store
			await this.patch(this.id, activitySessionPatch, { query: { $include: ['exercises'] } });
		},
		goToPrevExercise() {
			this.isSwitchingExercise = true;
			if (!this.isFirstExercise) {
				const activeExerciseIndex = this.activeExerciseIndex - 1;
				const activitySessionPatch = Object.assign({}, { activeExerciseIndex });
				// TODO: should convert to this.patch(this.id, activitySessionPatch)?
				// this.activitySession.patch patches locally first and then pushes it to the server whereas this.patch is a call to the server and then updates the local store
				this.activitySession.patch({ data: activitySessionPatch });
			}
		},
		async end(data) {
			// send patch request to "end" session
			await this.patch(this.id, { ended: true, ...data }, { query: { $include: ['exercises'] } });
			// TODO rename this exercise-generator service such that the remove command makes sense
			return getServiceStore('exercise-generator').remove(this.id);
		},
		unset() {
			this.id = undefined;
		},
		updateLastInteraction() {
			this.lastInteractionTime = moment.utc();
		},
		clearLastInteraction() {
			this.lastInteractionTime = undefined;
		}
	},
	hooks(getServiceStore) {
		return {
			before: {
				patch: [
					() => {
						const activitySessionsStore = getServiceStore('activity-sessions');
						// mark last interaction
						activitySessionsStore.updateLastInteraction();
					}
				]
			},
			after: {
				remove: [
					(context) => {
						// activities have an indirect association to activity sessions by storing the latest patient activity session for that activity.
						// Because this particular association is handled differently (see activities.js setupInstance where we don't call
						// defineSingleAssociation but instead loop through and manually add the props to the store), when we delete an activity session.
						// this association isn't being removed/updated.
						//
						// To solve this, we are manually removing the association here, if the activity session we're deleting is the latest
						// activity session for that patient of this activity

						// get the activity
						const activity = getServiceStore('activities').getFromStore(context.result.activityId);
						if (activity) {
							// if we have a latest patient activity session stored for this patient
							if (activity['latestPatientSession_' + context.result.profileId]) {
								// and it matches the activity session we're deleting
								if (activity['latestPatientSession_' + context.result.profileId].id == context.id) {
									// find the new latest session as we've just deleted this one
									getServiceStore('activities').find({
										query: {
											id: activity.id,
											$include: [
												{
													association: 'latestSession',
													as: 'latestPatientSession_' + context.result.profileId,
													defaultValue: {},
													scope: { profileId: context.result.profileId, location: context.result.location }
												}
											]
										}
									});
								}
							}
						}
					}
				]
			},
			error: {
				patch: [
					(context) => {
						if (context.error.name == 'ActivitySessionEnded') {
							const activitySessionsStore = getServiceStore('activity-sessions');
							const activitySession = activitySessionsStore.getFromStore(context.id);
							activitySession.status = context.error.data.status;
						}
					}
				]
			}
		};
	}
};
