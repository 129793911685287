<script>
export default {
	name: 'Audio',
	inject: ['$media', '$mediaAudio'],
	props: {
		name: {
			type: String,
			required: true
		},
		src: {
			type: String,
			required: true
		},
		muted: {
			type: Boolean
		},
		preload: {
			type: Boolean,
			default: true
		},
		playbackRate: {
			type: Number,
			default: 1
		}
	},
	emits: ['registered', 'queued', 'unqueued', 'play', 'pause', 'stop', 'end'],
	data() {
		return {
			type: 'audio'
		};
	},
	created() {
		this.$media.manager.emit('register', this);
	},
	beforeUnmount() {
		this.$media.manager.emit('unregister', this);
	},
	methods: {
		handleEndedEvent() {
			this.$emit('end');
			this.$mediaAudio.get().removeEventListener('ended', this.handleEndedEvent);
		},
		play() {
			this.$mediaAudio.get().src = this.src;
			this.$mediaAudio.get().playbackRate = this.playbackRate;
			this.$mediaAudio.get().muted = this.muted;
			return this.$mediaAudio
				.get()
				.play()
				.then(() => {
					this.$emit('play');
					this.$mediaAudio.get().addEventListener('ended', this.handleEndedEvent, { once: true });
					return this.$mediaAudio.get();
				})
				.catch((err) => {
					console.error(err);
					return this.$mediaAudio.get();
				});
		},
		pause() {
			this.$mediaAudio.get().pause();
			this.$emit('pause');
		},
		stop() {
			this.$mediaAudio.get().removeEventListener('ended', this.handleEndedEvent);
			this.$mediaAudio.get().pause();
			this.$mediaAudio.get().currentTime = 0;
			this.$emit('stop');
		}
	},
	render() {
		return null;
	}
};
</script>
