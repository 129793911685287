export default {
	servicePath: 'exercises',
	modelName: 'Exercise',
	instanceDefaults: {
		// don't need to specify the prop(s) inside the field objects for the field to be reactive
		answers: {},
		usedHints: {},
		status: null
	},
	setupInstance(data, { models, defineSingleAssociation }) {
		// Hack for instance defaults
		if (!data.status) data.status = null;

		defineSingleAssociation(data, 'level', models.api.Level);

		return data;
	},
	hooks(getServiceStore) {
		return {
			before: {
				patch: [
					() => {
						const activitySessionsStore = getServiceStore('activity-sessions');
						// mark last interaction
						activitySessionsStore.updateLastInteraction();
					}
				]
			},
			error: {
				patch: [
					(context) => {
						if (context.error.name == 'ActivitySessionEnded') {
							const activitySessionsStore = getServiceStore('activity-sessions');
							const activitySession = activitySessionsStore.getFromStore(context.data.activitySessionId);
							activitySession.status = context.error.data.status;
						}
					}
				]
			}
		};
	}
};
