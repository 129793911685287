export default {
	servicePath: 'activities',
	modelName: 'Activity',
	instanceDefaults: {
		levels: []
	},
	setupInstance(data, { models, defineSingleAssociation, defineManyAssociation }) {
		defineSingleAssociation(data, 'image', models.api.Image);
		defineSingleAssociation(data, 'stream', models.api.Stream);
		defineManyAssociation(data, 'levels', models.api.Level);
		defineManyAssociation(data, 'speechActivities', models.api.Activity);
		const latestPatientSessionKeys = Object.keys(data).filter((prop) => prop.startsWith('latestPatientSession_'));
		latestPatientSessionKeys.forEach((prop) => {
			//defineSingleAssociation(data, prop, models.api.ActivitySession);
			models.api.ActivitySession.store.addToStore(data[prop]);
			data[prop] = models.api.ActivitySession.getFromStore(data['_' + prop]) || {};
		});
		return data;
	}
};
