<script>
export default {
	name: 'Heading',
	props: {
		level: {
			type: Number,
			required: true,
			validator(value) {
				return [1, 2, 3, 4, 5, 6].indexOf(value) !== -1;
			}
		}
	},
	computed: {
		tag() {
			return 'h' + this.level;
		},
		headingClasses() {
			return ['heading', 'heading-' + this.level];
		}
	}
};
</script>
<template>
	<component :is="tag" :class="headingClasses">
		<slot />
	</component>
</template>

<style lang="scss">
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/functions.scss';

.heading {
	color: $primary-1;
	font-weight: 600;
	line-height: 1.5;

	&.heading-1 {
		font-size: rem(28);
	}
	&.heading-2 {
		font-size: rem(25);
	}
	&.heading-3 {
		font-size: rem(22);
	}
	&.heading-4 {
		font-size: rem(20);
	}
	&.heading-5 {
		font-size: rem(18);
	}
	&.heading-6 {
		font-size: rem(16);
	}
}
</style>
