import env from './env';
import * as observability from './observability';
import * as mixpanel from './mixpanel';
import * as productfruits from './productfruits';

observability.initialize();

import 'bootstrap/dist/css/bootstrap.min.css';
import { createApp, defineAsyncComponent, markRaw } from 'vue';
import { errorHandler } from './error-handler';

import App from './App.vue';
const app = createApp(App);

app.config.errorHandler = errorHandler;

import router from './router';

import { default as FeathersAPI, getServiceStore } from '@/plugins/FeathersAPI';
import services from './store/services';

import mitt from 'mitt';

app.provide('$exerciseEmitter', mitt());

app.use(FeathersAPI, {
	apis: [
		{
			name: 'api',
			url: env('API_URL'),
			idField: 'id',
			whitelist: ['$regex', '$options'],
			services: services,
			/** @type {Partial<import('@feathersjs/feathers').HooksObject>} */
			appHooks: {
				before: async (context) => {
					if (observability.isTracingEnabled()) {
						const { path, method } = context;
						const user = getServiceStore('auth').user;
						const userId = user && user.id;
						const span = await observability.createServiceSpan(path, method, userId);
						context.span = span;
					}
				},
				after: (context) => {
					if (observability.isTracingEnabled() && context.span) {
						const user = getServiceStore('auth').user;
						const userId = user && user.id;
						observability.endSpan(context.span, userId);
					}
				},
				error: (context) => {
					if (observability.isTracingEnabled() && context.span) {
						const { span, error } = context;
						const user = getServiceStore('auth').user;
						const userId = user && user.id;
						observability.endSpanWithError(span, error, userId);
					}
				}
			},
			auth: {
				userService: 'users',
				additionalParams: {
					query: { $include: [{ association: 'profile', $include: ['patients'] }] }
				},
				getters: {
					userPreferences(state) {
						return state.user
							? state.user.preferences.reduce((result, pref) => {
									result[pref.key] = pref.value;
									return result;
							  }, {})
							: {};
					}
				},
				onAuthenticate: (event) => {
					const { user, isReauthenticated } = event;
					if (!isReauthenticated) {
						observability.setUser(user.id.toString());
						mixpanel.authenticatedUser(user);
						productfruits.init(user);
						productfruits.initFeedback(user);
					}
				},
				onLogout: () => {
					observability.resetUser();
					mixpanel.reset();
					productfruits.reset();
				}
			}
		},
		{
			name: 'external-api',
			url: env('EXTERNAL_API_URL'),
			idField: '_id',
			whitelist: ['$regex', '$options'],
			services: [
				{
					servicePath: 'coca-db',
					modelName: 'CocaWord'
				},
				{
					servicePath: 'lesson-pix',
					modelName: 'LessonPixWord'
				},
				{
					servicePath: 'resemble-data',
					modelName: 'ResembleWord'
				},
				{
					servicePath: 'words-api',
					modelName: 'WordsApiWord'
				},
				{
					servicePath: 'word-net',
					modelName: 'WordNetWord'
				}
			],
			auth: { namespace: 'externalAuth' }
		}
	]
});
import DebugPlugin from '@/plugins/Debug';
app.use(DebugPlugin);

import Vue3TouchEvents from 'vue3-touch-events';
app.use(Vue3TouchEvents, {
	tapTolerance: 25
});

import MediaPlugin from '@/plugins/Media';
app.use(MediaPlugin);

import FormPlugin from '@/plugins/Form';
app.use(FormPlugin, {
	buttonComponent: defineAsyncComponent(() => import('@/components/buttons/BaseButton.vue')),
	primaryButtonProps: { color: 'secondary', size: 'lg' },
	secondaryButtonProps: { size: 'lg' },
	customComponents: {
		StatusInput: defineAsyncComponent(() => import('@/components/StatusInput.vue')),
		PermissionsInput: defineAsyncComponent(() => import('@/components/PermissionsInput.vue')),
		RolesInput: defineAsyncComponent(() => import('@/components/RolesInput.vue')),
		WordsInput: defineAsyncComponent(() => import('@/components/WordsInput.vue')),
		SingleWordInput: defineAsyncComponent(() => import('@/components/SingleWordInput.vue')),
		TagsInput: defineAsyncComponent(() => import('@/components/TagsInput.vue')),
		SemanticGroupsInput: defineAsyncComponent(() => import('@/components/SemanticGroupsInput.vue')),
		PrimarySemanticGroupsInput: defineAsyncComponent(() => import('@/components/PrimarySemanticGroupsInput.vue')),
		CategoriesInput: defineAsyncComponent(() => import('@/components/CategoriesInput.vue')),
		SoundsInput: defineAsyncComponent(() => import('@/components/SoundsInput.vue')),
		StartingSoundCuesInput: defineAsyncComponent(() => import('@/components/StartingSoundCuesInput.vue')),
		AudioSelectorInput: defineAsyncComponent(() => import('@/components/AudioSelectorInput.vue')),
		ImageSelectorInput: defineAsyncComponent(() => import('@/components/ImageSelectorInput.vue')),
		VideoSelectorInput: defineAsyncComponent(() => import('@/components/VideoSelectorInput.vue')),
		DefinitionInput: defineAsyncComponent(() => import('@/components/DefinitionInput.vue')),
		ActivitiesInput: defineAsyncComponent(() => import('@/components/ActivitiesInput.vue')),
		SolutionsInput: defineAsyncComponent(() => import('@/components/SolutionsInput.vue')),
		SolutionTypeInput: defineAsyncComponent(() => import('@/components/SolutionTypeInput.vue')),
		WordDataImporter: defineAsyncComponent(() => import('@/components/WordDataImporter.vue')),
		CustomSelectInput: defineAsyncComponent(() => import('@/components/formFields/CustomSelectInput.vue')),
		CustomDateInput: defineAsyncComponent(() => import('@/components/formFields/CustomDateInput.vue'))
	}
});

import PortalsPlugin from '@/plugins/Portals';
app.use(PortalsPlugin);

import ModalsPlugin from '@/plugins/Modals';
app.use(ModalsPlugin);

import PopoverPlugin from '@/plugins/Popover';
app.use(PopoverPlugin);

import AuthPlugin from '@/plugins/Authentication';
import logo from './assets/tactus-logo.svg';
app.use(AuthPlugin, {
	router,
	logo,
	loginRoute: '/',
	loginSuccessRoute: (auth) => {
		if (
			auth.user.fullPermissionsList.includes('patient') ||
			auth.user.fullPermissionsList.includes('patient-deactivated')
		)
			return '/homework';
		else return '/dashboard';
	},
	createAccountRoute: '/create-account/:accountType?',
	createAccountTemplate: defineAsyncComponent(() =>
		import('@/components/authentication/CreateAccountPageTemplate.vue')
	),
	createAccountFields: [
		{
			name: 'firstName',
			type: 'TextInput',
			label: 'First Name',
			required: true
		},
		{
			name: 'lastName',
			type: 'TextInput',
			label: 'Last Name'
		},
		{
			name: 'email',
			type: 'TextInput',
			label: 'Email Address',
			validators: ['isEmail'],
			required: true,
			props: {
				type: 'email'
			}
		},
		{
			name: 'tnc',
			type: 'SingleCheckboxInput',
			label: {
				component: markRaw({
					template:
						'<span>I agree to the <a href="https://tactustherapy.com/rehab/terms/" target="_blank" style="text-decoration:underline">Terms and Conditions</a> and <a href="https://tactustherapy.com/rehab/privacy/" target="_blank" style="text-decoration:underline">Privacy Policy</a></span>'
				})
			},
			required: true
		}
	],
	activateAccountRoute: '/activate-account/:accountType?',
	activateAccountTemplate: defineAsyncComponent(() =>
		import('@/components/authentication/ActivateAccountPageTemplate.vue')
	),
	activateAccountFields: [
		{
			name: 'profile.firstName',
			type: 'TextInput',
			label: 'First Name',
			props: {
				fieldHide: (formData, vm) => {
					const accountType = vm.$route.params.accountType;
					return accountType != 'patient';
				}
			},
			required: true
		},
		{
			name: 'profile.lastName',
			type: 'TextInput',
			label: 'Last Name',
			props: {
				fieldHide: (formData, vm) => {
					const accountType = vm.$route.params.accountType;
					return accountType != 'patient';
				}
			},
			required: true
		},
		{
			name: 'password',
			type: 'PasswordInput',
			label: 'Password',
			validators: ['isStrongPassword'],
			required: true
			// instructions: 'Passwords must be at least 12 characters'
		},
		{
			name: 'confirm_password',
			type: 'PasswordInput',
			label: 'Confirm Password',
			validators: ['isMatch:password'],
			required: true
		},
		{
			name: 'tnc',
			type: 'SingleCheckboxInput',
			label: {
				component: markRaw({
					template:
						'<span>I agree to the <a href="https://tactustherapy.box.com/v/beta-test-agreement" target="_blank" style="text-decoration:underline">Terms and Conditions</a> and <a href="https://tactustherapy.com/rehab/privacy/" target="_blank" style="text-decoration:underline">Privacy Policy</a></span>'
				})
			},
			props: {
				fieldHide: (formData, vm) => {
					const accountType = vm.$route.params.accountType;
					return accountType != 'beta-tester';
				}
			},
			required: true
		},
		{
			name: 'tnc',
			type: 'SingleCheckboxInput',
			label: {
				component: markRaw({
					template:
						'<span>I agree to the <a href="https://tactustherapy.com/rehab/terms/" target="_blank" style="text-decoration:underline">Terms and Conditions</a> and <a href="https://tactustherapy.com/rehab/privacy/" target="_blank" style="text-decoration:underline">Privacy Policy</a></span>'
				})
			},
			props: {
				fieldHide: (formData, vm) => {
					const accountType = vm.$route.params.accountType;
					return accountType != 'patient';
				}
			},
			required: true
		}
	]
});

import AdminPlugin from '@/plugins/Admin';
import isAuthenticated from './router/middleware/isAuthenticated';
import testRoutes from '~pages';
import { dailyProblemSolutionMappings, pictureProblemSolutionMappings } from '@/solution-type-mappings.js';
app.use(AdminPlugin, {
	router,
	middleware: [
		isAuthenticated,
		async ({ next }) => {
			const externalAuth = getServiceStore('externalAuth');
			await externalAuth.authenticate();
			return next();
		}
	],
	navComponent: { component: defineAsyncComponent(() => import('@/components/admin/AdminNav.vue')), props: {} },
	globalServiceIncludes: ['inactive'],
	customRoutes: [
		{
			path: 'users/invite/:inviteFlow?',
			name: 'InviteUser',
			component: () => import('@/views/admin/InviteUser.vue'),
			meta: {
				permissions: () => ['admin-users-invite']
			}
		},
		{
			path: 'tests',
			component: () => import('@/views/Tests.vue'),
			meta: {},
			children: [
				{
					path: '',
					name: 'TestsDefault',
					component: () => import('@/views/TestsDefault.vue')
				},
				...testRoutes
			]
		}
	],
	services: {
		maintenance: {
			label: 'Maintenance',
			singularLabel: 'Maintenance',
			name: 'maintenance',
			adminPath: 'maintenance',
			apiPath: 'maintenance',
			idField: 'id',
			displayAs: 'table',
			excludeGlobalIncludes: true,
			fields: [
				{
					displayField: {
						name: 'start',
						displayAs: 'datetime',
						displayOptions: ['sort'],
						sortable: true
					},
					formField: {
						name: 'start',
						type: 'DateInput',
						props: {
							includeTime: true
						}
					},
					label: 'Start Date'
				},
				{
					displayField: {
						name: 'end',
						displayAs: 'datetime'
					},
					formField: {
						name: 'end',
						type: 'DateInput',
						props: {
							includeTime: true
						}
					},
					label: 'End Date'
				},
				{
					displayField: {
						name: 'state'
					},
					formField: {
						name: 'state',
						type: 'RadiosInput',
						options: ['inactive', 'notify', 'active']
					},
					label: 'state'
				}
			]
		},
		users: {
			label: 'Users',
			singularLabel: 'User',
			name: 'users',
			adminPath: 'users',
			apiPath: 'users',
			idField: 'id',
			displayAs: 'table',
			disallowCreate: true,
			customActionComponents: [
				{ component: markRaw(defineAsyncComponent(() => import('@/components/admin/InviteUserButton.vue'))), props: {} }
			],
			customItemComponents: [
				markRaw({
					template: `<div class="text-nowrap"><router-link :to="'/admin/activity-sessions?filters[userId]='+item.id">View Activity Sessions</router-link></div>`,
					props: ['item']
				}),
				markRaw({
					template: `<div class="text-nowrap"><router-link :to="'/admin/sessions?filters[userId]='+item.id">View Login Sessions</router-link></div>`,
					props: ['item']
				})
			],
			fields: [
				{
					formField: {
						name: 'profile',
						type: 'FieldGroup',
						data: ['id', 'firstName', 'lastName'],
						fields: [
							{ name: 'firstName', type: 'TextInput', props: { required: true }, label: 'First Name' },
							{ name: 'lastName', type: 'TextInput', props: { required: true }, label: 'Last Name' }
						]
					}
				},
				{
					displayField: {
						name: 'profile.firstName',
						displayOptions: ['sort', 'search'],
						sortable: true,
						searchable: true
					},
					label: 'First Name'
				},
				{
					displayField: {
						name: 'profile.lastName',
						displayOptions: ['sort', 'search'],
						sortable: true,
						searchable: true
					},
					label: 'Last Name'
				},
				{
					// formField: {
					// 	name: 'email',
					// 	type: 'TextInput',
					// 	props: {
					// 		required: true,
					// 		validators: ['isEmail']
					// 	}
					// },
					displayField: {
						name: 'email',
						displayOptions: ['sort', 'search'],
						sortable: true,
						searchable: true
					},
					label: 'Email'
				},
				{
					displayField: {
						name: 'isVerified',
						displayAs: 'yes-no',
						displayOptions: ['sort'],
						sortable: true
					},
					label: 'Verified'
				},
				{
					displayField: {
						name: 'licenses',
						displayAs: (licenses) => {
							return {
								template: `<div><div v-for="license in licenses">
									<div><strong>{{license.licenseType.name}}</strong></div>
									<div class="ml-2">Description: {{license.licenseType.description}}</div>
									<div class="ml-2">Started On: {{license.startDate}}</div>
									<div class="ml-2">Expires On: {{license.expireDate}}</div>
									<div class="ml-2">Renewable: {{license.licenseType.isRenewable ? 'yes':'no'}}</div>
									<div class="ml-2">Permissions: <template v-for="(permission, index) in license.licenseType.role.permissions" :key="index"><span>{{permission.name+comma(index, license.licenseType.role.permissions.length)}}</span></template></div>

								</div></div>`,
								data() {
									return {
										licenses: licenses
									};
								},
								methods: {
									comma(index, length) {
										return index < length - 1 ? ', ' : '';
									}
								}
							};
						}
					},
					label: 'Licenses'
				},
				{
					formField: {
						name: 'roles.id',
						type: 'RolesInput',
						props: {
							required: true
						}
					},
					displayField: {
						name: 'roles',
						displayAs: (roles) => {
							return {
								template: `<div style="width: 300px;"><div v-for="role in roles">
									<div><strong>{{role.name}}</strong></div>
									<div class="ml-2">{{role.description}}</div>
									<!-- <div class="ml-2">Permissions: <template v-for="(permission, index) in role.permissions" :key="index"><span>{{permission.name+comma(index, role.permissions.length)}}</span></template></div> -->
								</div></div>`,
								data() {
									return {
										roles: roles
									};
								},
								methods: {
									comma(index, length) {
										return index < length - 1 ? ', ' : '';
									}
								}
							};
						}
					},
					label: 'Roles'
				},
				{
					displayField: {
						name: 'permissions.name',
						displayAs: 'list'
					},
					label: 'Permissions'
				}
				// {
				// 	displayField: {
				// 		name: 'fullPermissionsList',
				// 		displayAs: 'list'
				// 	},
				// 	label: 'Full Permissions List'
				// }
			]
		},
		roles: {
			label: 'Roles',
			singularLabel: 'Role',
			adminPath: 'roles',
			apiPath: 'roles',
			idField: 'id',
			displayAs: 'table',
			fields: [
				{
					formField: {
						name: 'name',
						type: 'TextInput',
						props: {
							required: true
						}
					},
					displayField: {
						name: 'name',
						displayOptions: ['sort', 'search'],
						sortable: true,
						searchable: true
					},
					label: 'Name'
				},
				{
					formField: {
						name: 'description',
						type: 'MultiLineTextInput',
						props: {
							required: true
						}
					},
					displayField: {
						name: 'description'
					},
					label: 'Description'
				},
				{
					formField: {
						name: 'permissions.id',
						type: 'PermissionsInput',
						props: {
							required: true
						}
					},
					displayField: {
						name: 'permissions.name',
						displayAs: 'list'
					},
					label: 'Permissions'
				}
			]
		},
		'license-types': {
			label: 'LicenseTypes',
			singularLabel: 'LicenseType',
			adminPath: 'license-types',
			apiPath: 'license-types',
			idField: 'id',
			displayAs: 'table',
			fields: [
				{
					formField: {
						name: 'name',
						type: 'TextInput',
						props: {
							required: true
						}
					},
					displayField: {
						name: 'name',
						displayOptions: ['sort', 'search'],
						sortable: true,
						searchable: true
					},
					label: 'Name'
				},
				{
					formField: {
						name: 'description',
						type: 'MultiLineTextInput',
						props: {
							required: true
						}
					},
					displayField: {
						name: 'description'
					},
					label: 'Description'
				},
				{
					displayField: {
						name: 'expirationPeriod',
						displayAs: 'number'
					},
					label: 'Expiration Period (days)'
				},
				{
					displayField: {
						name: 'isRenewable',
						displayAs: 'yes-no'
					},
					label: 'Is Renewable'
				},
				{
					displayField: {
						name: 'pricePerUser',
						displayAs: 'number'
					},
					label: 'Price Per User'
				},
				{
					displayField: {
						name: 'maxAllowedUsers',
						displayAs: 'number'
					},
					label: 'Max Allowed Users'
				},
				{
					displayField: {
						name: 'role',
						displayAs: (role) => {
							return {
								template: `<div>
									<div><strong>{{role.name}}</strong></div>
									<div class="ml-2">Description: {{role.description}}</div>
									<div class="ml-2">Permissions: <template v-for="(permission, index) in role.permissions" :key="index"><span>{{permission.name+comma(index, role.permissions.length)}}</span></template></div>
								</div>`,
								data() {
									return {
										role: role
									};
								},
								methods: {
									comma(index, length) {
										return index < length - 1 ? ', ' : '';
									}
								}
							};
						}
					},
					label: 'Role'
				}
			]
		},
		// permissions: {
		// 	label: 'Permissions',
		// 	singularLabel: 'Permission',
		// 	adminPath: 'permissions',
		// 	apiPath: 'permissions',
		// 	idField: 'id',
		// 	displayAs: 'table',
		// 	fields: [
		// 		{
		// 			formField: {
		// 				name: 'name',
		// 				type: 'TextInput',
		// 				props: {
		// 					required: true
		// 				}
		// 			},
		// 			displayField: {
		// 				name: 'name',
		// 				displayOptions: ['sort', 'search'],
		// 				sortable: true,
		// 				searchable: true
		// 			},
		// 			label: 'Name'
		// 		},
		// 		{
		// 			formField: {
		// 				name: 'description',
		// 				type: 'MultiLineTextInput',
		// 				props: {
		// 					required: true
		// 				}
		// 			},
		// 			displayField: {
		// 				name: 'description'
		// 			},
		// 			label: 'Description'
		// 		}
		// 	]
		// }
		sessions: {
			label: 'Login Sessions',
			singularLabel: 'Login Session',
			adminPath: 'sessions',
			apiPath: 'sessions',
			idField: 'id',
			displayAs: 'table',
			disallowCreate: true,
			disallowEdit: true,
			disallowDelete: true,
			includes: ['user'],
			customItemComponents: [],
			defaultSortField: ['startedAt'],
			defaultSortOrder: [-1],
			customFilters: [
				{
					component: markRaw(defineAsyncComponent(() => import('@/components/admin/filters/UsersFilter.vue')))
				}
			],
			fields: [
				{
					displayField: {
						name: 'user.profile.userId'
					},
					label: 'User'
				},
				{
					displayField: {
						name: 'startedAt',
						displayAs: 'datetime',
						displayOptions: ['sort'],
						sortable: true
					},
					label: 'Session Start'
				},
				{
					displayField: {
						name: 'endedAt',
						displayAs: 'datetime',
						displayOptions: ['sort'],
						sortable: true
					},
					label: 'Session End'
				},
				{
					displayField: {
						name: 'endReason'
					},
					label: 'Ended From'
				},
				{
					displayField: {
						name: 'userAgent'
					},
					label: 'User Agent String'
				}
			]
		},
		'activity-sessions': {
			label: 'Activity Sessions',
			singularLabel: 'Activity Session',
			adminPath: 'activity-sessions',
			apiPath: 'activity-sessions',
			idField: 'id',
			displayAs: 'table',
			disallowCreate: true,
			disallowEdit: true,
			disallowDelete: true,
			includes: ['profile', 'clinician', { association: 'activity', $include: ['stream'] }, 'exercises'],
			customItemComponents: [markRaw(defineAsyncComponent(() => import('@/components/admin/ReportViewer.vue')))],
			defaultSortField: ['createdAt'],
			defaultSortOrder: [-1],
			customFilters: [
				{
					component: markRaw(defineAsyncComponent(() => import('@/components/admin/filters/CliniciansFilter.vue'))),
					props: { label: 'Clinician', field: 'clinician.userId' }
				},
				{
					component: markRaw(defineAsyncComponent(() => import('@/components/admin/filters/ActivitiesFilter.vue')))
				}
			],
			fields: [
				{
					displayField: {
						name: 'createdAt',
						displayAs: 'datetime',
						displayOptions: ['sort'],
						sortable: true
					},
					label: 'Date'
				},
				{
					displayField: {
						name: 'clinician.userId'
					},
					label: 'Clinician'
				},
				{
					displayField: {
						name: 'profile.id'
					},
					label: 'Patient'
				},

				{
					displayField: {
						name: 'activity.displayName'
					},
					label: 'Activity'
				},
				{
					displayField: {
						name: 'discarded',
						displayOptions: ['filter'],
						filter: {
							name: 'discarded',
							label: 'Discarded',
							options: [
								{ label: 'Yes and No', value: JSON.stringify({ $in: [0, 1] }) },
								{ label: 'Yes', value: 1 },
								{ label: 'No', value: 0 }
							]
						},
						filterable: true
					},
					label: 'Discarded'
				},
				{
					displayField: {
						name: 'activity.type'
					},
					label: 'Activity Type'
				},
				{
					displayField: {
						name: 'activity.stream.displayName'
					},
					label: 'Stream'
				},
				{
					displayField: {
						name: 'exercises',
						displayAs: 'count'
					},
					label: 'Exercises'
				},
				{
					displayField: {
						name: 'duration',
						displayAs: 'duration'
					},
					label: 'Duration'
				}
			]
		},
		words: {
			label: 'Words',
			singularLabel: 'Word',
			adminPath: 'words',
			apiPath: 'words',
			idField: 'id',
			includeRevisions: true,
			includeImport: true,
			includes: [
				'parent',
				'defaultImage',
				'images',
				'audio',
				'video',
				{ association: 'definition', $include: ['primarySemanticGroup', 'secondarySemanticGroup'] },
				'initialSound',
				'startingSoundCue',
				'categories',
				'compoundConnectionWords',
				'updatedBy',
				'status',
				'revisions'
			],
			displayAs: 'table',
			customFilters: [
				{
					component: markRaw(defineAsyncComponent(() => import('@/components/admin/filters/SemanticGroupsFilter.vue')))
				}
			],
			fields: [
				{
					formField: {
						name: 'text',
						type: 'TextInput',
						props: {
							required: true
						}
					},
					displayField: {
						name: 'text',
						displayOptions: ['sort', 'search'],
						sortable: true,
						searchable: true
					},
					label: 'Text'
				},
				{
					displayField: {
						name: 'length',
						displayAs: 'number',
						displayOptions: ['sort'],
						sortable: true
					},
					label: 'Length'
				},
				{
					formField: {
						name: 'partOfSpeech',
						type: 'SelectInput',
						props: {
							options: [
								'adjective',
								'adverb',
								'article',
								'conjunction',
								'determiner',
								'existential there',
								'marker',
								'interjection',
								'negative',
								'noun',
								'number',
								'preposition',
								'pronoun',
								'verb'
							],
							required: true
						}
					},
					displayField: { name: 'partOfSpeech', displayOptions: ['filter'], filterable: true },
					label: 'POS'
				},
				{
					formField: {
						name: 'importData',
						type: 'WordDataImporter'
					}
				},
				{
					formField: {
						name: 'form',
						type: 'SelectInput',
						props: {
							options: [
								// nouns - pronouns
								'singular',
								'plural',
								// verbs
								'infinitive',
								'third-person-singular',
								'past',
								'present-participle',
								'past-participle',
								// adjectives
								'positive',
								'superlative',
								'comparative',
								// conjunctions
								'coordinating',
								'subordinating',
								'correlative',
								// prepositions
								'simple',
								'double',
								// adverbs
								'adverb-all',
								// interjections
								'interjection-all'
							],
							required: true
						}
					},
					displayField: { name: 'form', displayOptions: ['filter'], filterable: true },
					label: 'Form'
				},
				{
					formField: {
						name: 'isLemma',
						type: 'YesNoInput',
						props: {
							options: [
								{ label: 'yes', value: 1 },
								{ label: 'no', value: 0 }
							],
							required: true
						}
					},
					displayField: {
						name: 'isLemma',
						displayAs: 'yes-no',
						displayOptions: ['sort'],
						sortable: true
					},
					label: 'Is Lemma'
				},
				{
					formField: {
						name: 'definition',
						type: 'FieldGroup',
						data: ['id', 'text', 'wordSenseText', 'wordSenseId', 'semanticGroups'],
						fields: [
							{ type: 'DefinitionInput', props: { required: true } },
							{ name: 'semanticGroups', type: 'SemanticGroupsInput', label: 'Semantic Groups' }
						]
					},
					displayField: { name: 'definition.text', multiEdit: false },
					label: 'Definition'
				},
				{
					formField: {
						name: 'updatedWordSenseSearch',
						type: 'HiddenInput'
					}
				},
				{
					displayField: {
						name: 'definition.wordSenseText',
						displayOptions: [],
						multiEdit: false
					},
					label: 'WordSense'
				},
				{
					displayField: {
						displayAs: (item) => {
							return item.definition && item.definition.primarySemanticGroup
								? item.definition.secondarySemanticGroup
									? {
											template: `<div><div class="text-nowrap">Primary: ${item.definition.primarySemanticGroup.text}</div><div class="text-nowrap">Secondary: ${item.definition.secondarySemanticGroup.text}</div></div>`
									  }
									: {
											template: `<div><div class="text-nowrap">Primary: ${item.definition.primarySemanticGroup.text}</div></div>`
									  }
								: { template: '<div></div>' };
						},
						multiEdit: false
					},
					label: 'Semantic Groups'
				},
				{
					formField: {
						name: 'transcription',
						type: 'TextInput',
						props: {
							required: true,
							readonly: true
						}
					},
					displayField: { name: 'transcription', multiEdit: false },
					label: 'Transcription'
				},
				{
					formField: {
						name: 'updatedTranscription',
						type: 'HiddenInput'
					}
				},
				{
					formField: {
						name: 'displayTranscription',
						type: 'HiddenInput'
					},
					displayField: { name: 'displayTranscription', multiEdit: false },
					label: 'Display Transcription'
				},
				{
					formField: {
						name: 'shape',
						type: 'TextInput',
						props: {
							required: true,
							readonly: true
						}
					},
					displayField: { name: 'shape', multiEdit: false },
					label: 'Shape'
				},
				{
					formField: {
						name: 'syllables',
						type: 'NumberInput',
						props: {
							required: true,
							readonly: true
						}
					},
					displayField: {
						name: 'syllables',
						displayAs: 'number',
						displayOptions: ['sort'],
						sortable: true,
						multiEdit: false
					},
					label: 'Syllables'
				},
				{
					formField: {
						name: 'initialSpelling',
						type: 'TextInput',
						props: {
							required: true,
							readonly: true
						}
					},
					displayField: { name: 'initialSpelling', multiEdit: false },
					label: 'Initial Spelling'
				},
				{
					formField: {
						name: 'initialSoundId',
						type: 'SoundsInput',
						props: {
							required: true,
							readonly: true,
							soundsQuery: { isInitial: true }
						}
					},
					displayField: { name: 'initialSound.ipaSound', multiEdit: false },
					label: 'Initial Sound'
				},
				{
					formField: {
						name: 'startingSoundCueId',
						type: 'startingSoundCuesInput',
						props: {
							required: true,
							readonly: true
						}
					},
					displayField: { name: 'startingSoundCue.ipa', multiEdit: false },
					label: 'Starting Sound Cue'
				},
				{
					formField: {
						name: 'rhymeSound',
						type: 'TextInput',
						props: {
							required: true,
							readonly: true
						}
					},
					displayField: { name: 'rhymeSound', multiEdit: false },
					label: 'Rhyme Sound'
				},
				{
					formField: {
						name: 'displayRhymeSound',
						type: 'HiddenInput'
					},
					displayField: { name: 'displayRhymeSound', multiEdit: false },
					label: 'Display Rhyme Sound'
				},
				{
					formField: {
						name: 'spellingType',
						type: 'SelectInput',
						props: {
							options: ['regular', 'semi-regular', 'irregular'],
							required: true,
							readonly: true
						}
					},
					displayField: {
						name: 'spellingType',
						displayOptions: ['sort', 'filter'],
						sortable: true,
						filterable: true,
						multiEdit: false
					},
					label: 'Spelling Type'
				},
				{
					formField: {
						name: 'compoundType',
						type: 'SelectInput',
						props: {
							options: ['SW', 'CCW', 'HCW', 'OCW'],
							required: true
						}
					},
					displayField: { name: 'compoundType', displayOptions: ['filter'], filterable: true },
					label: 'Compound Type'
				},
				{
					formField: {
						name: 'compoundConnectionWords.id',
						type: 'WordsInput',
						props: {
							fieldHide: (formData) => {
								return (
									formData.compoundType != 'CCW' && formData.compoundType != 'HCW' && formData.compoundType != 'OCW'
								);
							}
						}
					},
					displayField: { name: 'compoundConnectionWords.text', displayAs: 'list' },
					label: 'Compound Connection Words'
				},
				{
					formField: {
						name: 'frequency',
						type: 'NumberInput',
						props: {
							readonly: true
							// required: true
						}
					},
					displayField: {
						name: 'frequency',
						displayAs: 'number',
						displayOptions: ['sort'],
						sortable: true,
						multiEdit: false
					},
					label: 'Frequency'
				},
				{
					formField: {
						name: 'wordRank',
						type: 'NumberInput',
						props: {
							readonly: true
							// required: true
						}
					},
					displayField: {
						name: 'wordRank',
						displayAs: 'number',
						displayOptions: ['sort'],
						sortable: true,
						multiEdit: false
					},
					label: 'Word Rank'
				},
				{
					formField: { name: 'parentId', type: 'SingleWordInput' },
					displayField: { name: 'parent.text', displayOptions: ['sort'], sortable: true },
					label: 'Lemma'
				},
				{
					formField: {
						name: 'lemmaRank',
						type: 'NumberInput',
						props: {
							readonly: true
							// required: true
						}
					},
					displayField: {
						name: 'lemmaRank',
						displayAs: 'number',
						displayOptions: ['sort'],
						sortable: true,
						multiEdit: false
					},
					label: 'Lemma Rank'
				},
				{
					formField: {
						name: 'isMassNoun',
						type: 'YesNoInput',
						props: {
							options: [
								{ label: 'yes', value: 1 },
								{ label: 'no', value: 0 }
							],
							required: true
						}
					},
					displayField: {
						name: 'isMassNoun',
						displayAs: 'yes-no',
						displayOptions: ['sort', 'filter'],
						sortable: true,
						filterable: true
					},
					label: 'Is Mass Noun'
				},
				{
					formField: { name: 'defaultImageId', type: 'ImageSelectorInput' },
					displayField: {
						name: 'defaultImage.fullpath',
						displayAs: 'image-thumb',
						displayOptions: ['filter'],
						filter: {
							name: 'defaultImageId',
							label: 'Has Default Image',
							options: [
								{ label: 'yes', value: JSON.stringify({ $ne: null }) },
								{ label: 'no', value: JSON.stringify({ $eq: null }) }
							]
						},
						filterable: true,
						multiEdit: false
					},
					label: 'Default Image'
				},
				{
					formField: {
						name: 'audioId',
						type: 'AudioSelectorInput',
						props: {
							// required: true
						}
					},
					displayField: { name: 'audio.fullpath', displayAs: 'audio', multiEdit: false },
					label: 'Audio File'
				},
				{
					formField: {
						name: 'videoId',
						type: 'VideoSelectorInput',
						props: {
							// required: true
						}
					},
					displayField: { name: 'video.fullpath', displayAs: 'video', multiEdit: false },
					label: 'Video File'
				},
				// {
				// 	formField: { name: 'categories.id', type: 'CategoriesInput' },
				// 	displayField: { name: 'categories.text', displayAs: 'list' },
				// 	label: 'Categories'
				// },
				{
					formField: { name: 'statusId', type: 'StatusInput' },
					displayField: {
						name: 'status.text',
						displayOptions: ['filter'],
						filter: { options: ['unverified', 'draft', 'alpha', 'beta', 'approved'] },
						filterable: true
					},
					label: 'Status'
				},
				{
					formField: {
						name: 'active',
						type: 'YesNoInput',
						props: {
							options: [
								{ label: 'yes', value: 1 },
								{ label: 'no', value: 0 }
							],
							required: true
						}
					},
					displayField: {
						name: 'active',
						displayAs: 'yes-no',
						displayOptions: ['sort', 'filter'],
						sortable: true,
						filterable: true
					},
					label: 'Active'
				},
				{
					displayField: { name: 'createdAt', displayAs: 'date', displayOptions: ['sort'], sortable: true },
					label: 'Created At'
				}
			]
		},
		definitions: {
			label: 'Definitions',
			singularLabel: 'Definition',
			adminPath: 'definitions',
			apiPath: 'definitions',
			idField: 'id',
			includeRevisions: true,
			includes: ['primarySemanticGroup', 'secondarySemanticGroup', 'words', 'audio', 'updatedBy', 'revisions'],
			displayAs: 'table',
			fields: [
				{
					displayField: {
						name: 'words.text',
						displayAs: 'list',
						displayOptions: []
					},
					label: 'Connected Words'
				},
				{
					formField: {
						name: 'text',
						type: 'MultiLineTextInput',
						props: {
							required: true
						}
					},
					displayField: {
						name: 'text',
						displayOptions: ['sort', 'search'],
						sortable: true,
						searchable: true
					},
					label: 'Text'
				},
				{
					formField: {
						name: 'wordSenseText',
						type: 'TextInput',
						props: {
							readonly: true
						}
					},
					displayField: {
						name: 'wordSenseText',
						displayOptions: [],
						multiEdit: false
					},
					label: 'WordSense Text'
				},
				{
					formField: {
						name: 'wordSenseId',
						type: 'TextInput',
						props: {
							readonly: true
						}
					},
					displayField: {
						name: 'wordSenseId',
						displayOptions: [],
						multiEdit: false
					},
					label: 'WordSense ID'
				},
				{
					formField: { name: 'semanticGroups', type: 'SemanticGroupsInput' },
					displayField: {
						displayAs: (item) => {
							return item.primarySemanticGroup && item.secondarySemanticGroup
								? {
										template: `<div><div class="text-nowrap">Primary: ${item.primarySemanticGroup.text}</div><div class="text-nowrap">Secondary: ${item.secondarySemanticGroup.text}</div></div>`
								  }
								: item.primarySemanticGroup
								? {
										template: `<div><div class="text-nowrap">Primary: ${item.primarySemanticGroup.text}</div></div>`
								  }
								: { template: '<div></div>' };
						}
					},
					label: 'Semantic Groups'
				},
				{
					formField: {
						name: 'audioId',
						type: 'AudioSelectorInput'
					},
					displayField: { name: 'audio.fullpath', displayAs: 'audio', multiEdit: false },
					label: 'Audio File'
				}
			]
		},
		directions: {
			label: 'Directions',
			singularLabel: 'Direction',
			adminPath: 'directions',
			apiPath: 'directions',
			idField: 'id',
			includes: ['image', 'audio'],
			displayAs: 'table',
			fields: [
				{
					formField: {
						name: 'text',
						type: 'TextInput',
						props: {
							required: true
						}
					},
					displayField: {
						name: 'text',
						displayOptions: ['sort', 'search'],
						sortable: true,
						searchable: true
					},
					label: 'Text'
				},
				{
					formField: {
						name: 'category',
						type: 'TextInput',
						props: {
							required: true
						}
					},
					displayField: {
						name: 'category',
						displayOptions: ['sort'],
						sortable: true
					},
					label: 'Category'
				},
				{
					formField: {
						name: 'verb',
						type: 'TextInput',
						props: {
							required: true
						}
					},
					displayField: {
						name: 'verb',
						displayOptions: ['sort', 'search'],
						sortable: true,
						searchable: true
					},
					label: 'Verb'
				},
				{
					formField: {
						name: 'noun',
						type: 'TextInput',
						props: {
							required: true
						}
					},
					displayField: {
						name: 'noun',
						displayOptions: ['sort', 'search'],
						sortable: true,
						searchable: true
					},
					label: 'Noun'
				},
				{
					formField: {
						name: 'other',
						type: 'TextInput'
					},
					displayField: {
						name: 'position',
						displayOptions: ['sort'],
						sortable: true
					},
					label: 'Position'
				},
				{
					formField: { name: 'imageId', type: 'ImageSelectorInput' },
					displayField: {
						name: 'image.fullpath',
						displayAs: 'image-thumb',
						multiEdit: false
					},
					label: 'Default Image'
				},
				{
					formField: {
						name: 'audioId',
						type: 'AudioSelectorInput',
						props: {
							// required: true
						}
					},
					displayField: { name: 'audio.fullpath', displayAs: 'audio', multiEdit: false },
					label: 'Audio File'
				}
			]
		},
		images: {
			label: 'Images',
			singularLabel: 'Image',
			adminPath: 'images',
			apiPath: 'images',
			idField: 'id',
			includeRevisions: true,
			includes: ['defaultWords', 'sizes', 'updatedBy', 'revisions'],
			displayAs: 'table',
			customActionComponents: [
				{ component: defineAsyncComponent(() => import('@/components/admin/Shutterstock.vue')), props: {} }
			],
			fields: [
				{
					displayField: { name: 'fullpath', displayAs: 'image-thumb' },
					label: 'Preview'
				},
				{
					formField: {
						name: 'filename',
						type: 'ImageFileInput',
						props: {
							required: true,
							accept: ['image/jpeg', 'image/png'],
							uploadUrl: env('API_URL') + '/uploads/images'
						},
						events: {
							remove: ($event, formData) => {
								console.log('remove', $event, formData);
								formData.shutterstockId = null;
							}
						}
					},
					displayField: {
						name: 'filename',
						displayOptions: ['sort', 'search'],
						sortable: true,
						searchable: true,
						multiEdit: false
					},
					label: 'Image File'
				},
				{
					displayField: {
						name: 'filesize',
						displayAs: 'filesize',
						displayOptions: ['sort'],
						sortable: true,
						multiEdit: false
					},
					label: 'Filesize'
				},
				{
					displayField: {
						name: 'width',
						displayAs: 'number',
						displayOptions: ['sort'],
						sortable: true,
						multiEdit: false
					},
					label: 'Width (px)'
				},
				{
					displayField: {
						name: 'height',
						displayAs: 'number',
						displayOptions: ['sort'],
						sortable: true,
						multiEdit: false
					},
					label: 'Height (px)'
				},
				{
					displayField: {
						name: 'aspectRatio',
						displayAs: 'number',
						displayOptions: ['sort'],
						sortable: true,
						multiEdit: false
					},
					label: 'Aspect Ratio'
				},
				{
					displayField: {
						name: 'sizes',
						displayAs: 'image-sizes'
					},
					label: 'Generated Sizes'
				},
				{
					formField: {
						name: 'type',
						type: 'SelectInput',
						props: {
							options: ['isolated', 'scene'],
							required: true
						}
					},
					displayField: { name: 'type', displayOptions: ['filter'], filterable: true },
					label: 'Type of Image'
				},
				{
					formField: {
						name: 'shutterstockId',
						type: 'HiddenInput'
					},
					displayField: { name: 'shutterstockId', displayOptions: [], multiEdit: false },
					label: 'Shutterstock ID'
				},
				{
					formField: { name: 'defaultWords.id', type: 'WordsInput' },
					displayField: {
						name: 'defaultWords.text',
						displayAs: 'list',
						displayOptions: ['filter'],
						filter: {
							label: 'Has Default Word',
							options: [
								{ label: 'yes', value: JSON.stringify({ $ne: null }) },
								{ label: 'no', value: JSON.stringify({ $eq: null }) }
							]
						},
						filterable: true
					},
					label: 'Default Image Words'
				}
			]
		},

		letters: {
			label: 'Letters',
			singularLabel: 'Letter',
			adminPath: 'letters',
			apiPath: 'letters',
			idField: 'id',
			includeRevisions: true,
			includes: ['audio', 'exampleWord', 'relatedCase', 'sound', 'secondarySound', 'updatedBy', 'revisions'],
			displayAs: 'table',
			fields: [
				{
					formField: { name: 'text', type: 'TextInput', props: { required: true, validators: ['isMaxLength:1'] } },
					displayField: { name: 'text', displayOptions: ['sort'], sortable: true },
					label: 'Text'
				},
				{
					formField: { name: 'type', type: 'SelectInput', props: { options: ['consonant', 'vowel'], required: true } },
					displayField: { name: 'type', displayOptions: ['filter'], filterable: true },
					label: 'Type'
				},
				{
					formField: { name: 'case', type: 'SelectInput', props: { options: ['upper', 'lower'], required: true } },
					displayField: { name: 'case', displayOptions: ['filter'], filterable: true },
					label: 'Case'
				},
				{
					formField: {
						name: 'shape',
						type: 'SelectInput',
						props: {
							options: [...['curvy', 'diagonal', 'round', 'straight'], ...['lines', 'post', 'short', 'tall']],
							required: true
						}
					},
					displayField: { name: 'shape', displayOptions: ['filter'], filterable: true },
					label: 'Shape'
				},
				{
					formField: {
						name: 'nameClass',
						type: 'selectInput',
						props: { options: ['ay', 'ee', 'eh', 'eye', 'ooh'], required: true }
					},
					displayField: { name: 'nameClass', displayOptions: ['filter'], filterable: true },
					label: 'Name Class'
				},
				{
					formField: {
						name: 'group',
						type: 'selectInput',
						props: { options: ['C1', 'C2', 'C3', 'C4', 'V1'], required: true }
					},
					displayField: { name: 'group', displayOptions: ['filter'], filterable: true },
					label: 'Letter Group'
				},
				{
					formField: {
						name: 'keyboardHand',
						type: 'selectInput',
						props: { options: ['left', 'right'], required: true }
					},
					displayField: { name: 'keyboardHand', displayOptions: ['filter'], filterable: true },
					label: 'Keyboard Hand'
				},
				{
					formField: {
						name: 'keyboardRow',
						type: 'selectInput',
						props: { options: ['top', 'middle', 'bottom'], required: true }
					},
					displayField: { name: 'keyboardRow', displayOptions: ['filter'], filterable: true },
					label: 'Keyboard Row'
				},
				{
					formField: { name: 'audioId', type: 'AudioSelectorInput', props: { required: true } },
					displayField: { name: 'audio.fullpath', displayAs: 'audio', multiEdit: false },
					label: 'Audio File'
				},
				{
					formField: { name: 'soundId', type: 'SoundsInput', props: { required: true } },
					displayField: { name: 'sound.ipaSound' },
					label: 'Sound'
				},
				{
					formField: { name: 'secondarySoundId', type: 'SoundsInput', props: { required: true } },
					displayField: { name: 'secondarySound.ipaSound' },
					label: 'Secondary Sound'
				},
				{
					formField: { name: 'exampleWordId', type: 'SingleWordInput' },
					displayField: { name: 'exampleWord.text' },
					label: 'Example Word'
				},
				{
					formField: { name: 'relatedCaseId' },
					displayField: { name: 'relatedCase.text' },
					label: 'Related Case'
				}
			]
		},
		sounds: {
			label: 'Sounds',
			singularLabel: 'Sound',
			adminPath: 'sounds',
			apiPath: 'sounds',
			idField: 'id',
			includes: ['initialAudio', 'finalAudio', 'isolatedVideo'],
			displayAs: 'table',
			fields: [
				{
					formField: { name: 'spelling', type: 'TextInput', props: { required: true } },
					displayField: { name: 'spelling', displayOptions: ['sort'], sortable: true },
					label: 'Spelling'
				},
				{
					formField: {
						name: 'type',
						type: 'selectInput',
						props: { options: ['blend', 'consonant', 'vowel'], required: true }
					},
					displayField: { name: 'type', displayOptions: ['filter'], filterable: true },
					label: 'Type'
				},
				{
					formField: {
						name: 'subtype',
						type: 'selectInput',
						props: {
							options: ['diphthong', 'triphthong', 'single'],
							fieldHide: (formData) => {
								return formData.type != 'vowel';
							}
						}
					},
					displayField: { name: 'subtype', displayOptions: ['filter'], filterable: true },
					label: 'SubType'
				},
				{
					formField: {
						name: 'isRhotic',
						type: 'YesNoInput',
						props: {
							options: [
								{ label: 'yes', value: 1 },
								{ label: 'no', value: 0 }
							],
							required: true
						}
					},
					displayField: {
						name: 'isRhotic',
						displayAs: 'yes-no',
						displayOptions: ['filter'],
						filterable: true
					},
					label: 'Is Rhotic'
				},
				{
					formField: { name: 'ipaSound', type: 'TextInput', props: { required: true } },
					displayField: { name: 'ipaSound', displayOptions: ['sort'], sortable: true },
					label: 'IPA Sound'
				},
				{
					formField: { name: 'charMapCode', type: 'TextInput', props: { validators: ['isMaxLength:1'] } },
					displayField: { name: 'charMapCode', displayOptions: ['sort'], sortable: true },
					label: 'Char Map Code'
				},
				{
					formField: {
						name: 'length',
						type: 'selectInput',
						props: {
							options: ['long', 'short'],
							fieldHide: (formData) => {
								return formData.type != 'vowel';
							}
						}
					},
					displayField: { name: 'length', displayOptions: ['filter'], filterable: true },
					label: 'Length'
				},
				{
					formField: {
						name: 'place',
						type: 'selectInput',
						props: {
							options: [
								'alveolar',
								'back',
								'bilabial',
								'central',
								'dental',
								'front',
								'glottal',
								'labiodental',
								'palatal',
								'postalveolar',
								'velar'
							],
							fieldHide: (formData) => {
								return formData.type == 'blend';
							}
						}
					},
					displayField: { name: 'place', displayOptions: ['filter'], filterable: true },
					label: 'Place'
				},
				{
					formField: {
						name: 'manner',
						type: 'selectInput',
						props: {
							options: [
								'affricate',
								'approximant',
								'close',
								'close-mid',
								'fricative',
								'mid',
								'nasal',
								'open',
								'open-mid',
								'stop'
							],
							fieldHide: (formData) => {
								return formData.type == 'blend';
							}
						}
					},
					displayField: { name: 'manner', displayOptions: ['filter'], filterable: true },
					label: 'Manner'
				},
				{
					formField: {
						name: 'quality',
						type: 'selectInput',
						props: {
							options: ['rounded', 'unrounded', 'voiced', 'voiceless'],
							fieldHide: (formData) => {
								return formData.type == 'blend';
							}
						}
					},
					displayField: { name: 'quality', displayOptions: ['filter'], filterable: true },
					label: 'Quality'
				},
				{
					formField: {
						name: 'isInitial',
						type: 'YesNoInput',
						props: {
							options: [
								{ label: 'yes', value: 1 },
								{ label: 'no', value: 0 }
							],
							required: true
						}
					},
					displayField: {
						name: 'isInitial',
						displayAs: 'yes-no',
						displayOptions: ['filter'],
						filterable: true
					},
					label: 'Is Initial'
				},
				{
					formField: {
						name: 'InitialAudioId',
						type: 'AudioSelectorInput',
						props: {
							fieldHide: (formData) => {
								return formData.isIntial == false;
							}
						}
					},
					displayField: { name: 'initialAudio.fullpath', displayAs: 'audio' },
					label: 'Initial Audio'
				},
				{
					formField: {
						name: 'isFinal',
						type: 'YesNoInput',
						props: {
							options: [
								{ label: 'yes', value: 1 },
								{ label: 'no', value: 0 }
							],
							required: true
						}
					},
					displayField: {
						name: 'isFinal',
						displayAs: 'yes-no',
						displayOptions: ['filter'],
						filterable: true
					},
					label: 'Is Final'
				},
				{
					formField: {
						name: 'finalAudioId',
						type: 'AudioSelectorInput',
						props: {
							fieldHide: (formData) => {
								return formData.isFinal == false;
							}
						}
					},
					displayField: { name: 'finalAudio.fullpath', displayAs: 'audio' },
					label: 'Final Audio'
				},
				{
					formField: {
						name: 'isIsolated',
						type: 'YesNoInput',
						props: {
							options: [
								{ label: 'yes', value: 1 },
								{ label: 'no', value: 0 }
							],
							required: true
						}
					},
					displayField: {
						name: 'isIsolated',
						displayAs: 'yes-no',
						displayOptions: ['filter'],
						filterable: true
					},
					label: 'Is Isolated'
				},
				{
					formField: {
						name: 'isolatedVideoId',
						type: 'VideoSelectorInput',
						props: {
							fieldHide: (formData) => {
								return formData.isIsolated == false;
							}
						}
					},
					displayField: { name: 'isolatedVideo.fullpath', displayAs: 'video' },
					label: 'Isolated Video'
				}
			]
		},
		audio: {
			label: 'Audio',
			singularLabel: 'Audio',
			adminPath: 'audio',
			apiPath: 'audio',
			idField: 'id',
			includeRevisions: true,
			includes: ['updatedBy', 'revisions'],
			displayAs: 'table',
			disallowMultiEdit: true,
			fields: [
				{
					formField: { name: 'fullpath' },
					displayField: { name: 'fullpath', displayAs: 'audio' },
					label: 'Preview'
				},
				{
					formField: {
						name: 'filename',
						type: 'AudioFileInput',
						props: {
							required: true,
							accept: ['audio/mpeg', 'audio/x-m4a'],
							uploadUrl: env('API_URL') + '/uploads/audio'
						}
					},
					displayField: {
						name: 'filename',
						displayOptions: ['sort', 'search'],
						sortable: true,
						searchable: true,
						multiEdit: false
					},
					label: 'Audio File'
				}
			]
		},
		videos: {
			label: 'Videos',
			singularLabel: 'Video',
			adminPath: 'videos',
			apiPath: 'videos',
			idField: 'id',
			// includes: ['updatedBy'],
			displayAs: 'table',
			disallowMultiEdit: true,
			fields: [
				{
					formField: { name: 'fullpath' },
					displayField: { name: 'fullpath', displayAs: 'video' },
					label: 'Preview'
				},
				{
					formField: {
						name: 'filename',
						type: 'VideoFileInput',
						props: {
							required: true,
							accept: ['video/mp4'],
							uploadUrl: env('API_URL') + '/uploads/videos'
						}
					},
					displayField: {
						name: 'filename',
						displayOptions: ['sort', 'search'],
						sortable: true,
						searchable: true,
						multiEdit: false
					},
					label: 'Video File'
				}
			]
		},
		'semantic-groups': {
			label: 'Semantic Groups',
			singularLabel: 'Semantic Group',
			adminPath: 'semantic-groups',
			apiPath: 'semantic-groups',
			idField: 'id',
			includeRevisions: true,
			includes: ['parent', 'updatedBy', 'revisions', 'status'],
			displayAs: 'table',
			fields: [
				{
					formField: { name: 'text', type: 'TextInput', props: { required: true } },
					displayField: { name: 'text', displayOptions: ['sort', 'search'], sortable: true, searchable: true },
					label: 'Text'
				},
				{
					formField: { name: 'description', type: 'MultiLineTextInput', props: { required: true } },
					displayField: { name: 'description', displayOptions: ['sort', 'search'], sortable: true, searchable: true },
					label: 'Description'
				},
				{
					formField: {
						name: 'partOfSpeech',
						type: 'SelectInput',
						props: {
							options: [
								'adjective',
								'adverb',
								'article',
								'conjunction',
								'determiner',
								'existential there',
								'marker',
								'interjection',
								'negative',
								'noun',
								'number',
								'preposition',
								'pronoun',
								'verb'
							],
							required: true
						}
					},
					displayField: { name: 'partOfSpeech', displayOptions: ['filter'], filterable: true },
					label: 'POS'
				},
				{
					formField: { name: 'parentId', type: 'PrimarySemanticGroupsInput', props: { allowNew: false } },
					displayField: { name: 'parent.text', displayOptions: ['sort'], sortable: true },
					label: 'Parent'
				},
				{
					formField: { name: 'wordNetSemanticGroup', type: 'TextInput' },
					displayField: { name: 'wordNetSemanticGroup', displayOptions: [] },
					label: 'WordNet SemanticGroup'
				},
				{
					formField: { name: 'wordNetHypernyms', type: 'TextInput' },
					displayField: { name: 'wordNetHypernyms', displayOptions: [] },
					label: 'WordNet Hypernyms'
				},
				{
					formField: { name: 'statusId', type: 'StatusInput' },
					displayField: { name: 'status.text' },
					label: 'Status'
				}
			]
		},
		// 		categories: {
		// 			label: 'Categories',
		// 			singularLabel: 'Category',
		// 			adminPath: 'categories',
		// 			apiPath: 'categories',
		// 			idField: 'id',
		// 			includeRevisions: true,
		// 			includes: ['parent', 'updatedBy', 'revisions'],
		// 			displayAs: 'table',
		// 			fields: [
		// 				{
		// 					formField: { name: 'text', type: 'TextInput', props: { required: true } },
		// 					displayField: { name: 'text', displayOptions: ['sort', 'search'], sortable: true, searchable: true },
		// 					label: 'Text'
		// 				},
		// 				{
		// 					formField: { name: 'parentId', type: 'SingleCategoryInput', props: { allowNew: false } },
		// 					displayField: { name: 'parent.text', displayOptions: ['sort'], sortable: true },
		// 					label: 'Parent'
		// 				}
		// 			]
		// 		},
		problems: {
			// service definition - needs to be a unique service name
			label: 'Problems', // plural service label - can be any text - can be seen on the admin dashboard, navigation
			singularLabel: 'Problem', // singular version of plural service label - can be any text - can be seen on pages when creating or editing
			adminPath: 'problems', // url slug for the admin panel, eg:http://localhost:8080/admin/problems
			apiPath: 'problems', // api url, eg:http://localhost:3030/problems
			idField: 'id', // needs to correspond with the id field of the model, eg. for Mongo service it would be '_id', for Sequelize services, it's always 'id'
			includes: ['audio', 'activity', 'image', { association: 'solutions' }], // needs to correspond with model association names
			displayAs: 'table', // doesn't do anything now, always set as 'table'
			disallowMultiEdit: true,
			defaultSortField: ['activityId', 'category'], // array of sort fields, when defaultSortField is not defined, default sort is by id
			customFilters: [
				{
					props: { query: { id: { $in: [30, 31, 32] } } },
					component: defineAsyncComponent(() => import('@/components/admin/filters/ActivitiesFilter.vue'))
				}
			],
			fields: [
				// array of fields corresponding to both the list page as well as the edit/create page
				{
					formField: {
						// used to define edit and create fields
						name: 'activityId', // needs to correspond to property field of object
						type: 'ActivitiesInput', // all possible inputs: TextInput,MultiLineTextInput,NumberInput,PasswordInput,SelectInput,RadiosInput,YesNoInput,FileInput,ImageFileInput,AudioFileInput,VideoFileInput,TagInput
						props: { required: true } // additional props that get passed into the Form plugin
					},
					displayField: { name: 'activity.displayName' },
					label: 'Activity'
				},
				{
					formField: {
						// used to define edit and create fields
						name: 'text', // needs to correspond to property field of object
						type: 'TextInput', // all possible inputs: TextInput,MultiLineTextInput,NumberInput,PasswordInput,SelectInput,RadiosInput,YesNoInput,FileInput,ImageFileInput,AudioFileInput,VideoFileInput,TagInput
						props: { required: true } // additional props that get passed into the Form plugin
					},
					displayField: {
						// used to define list view fields (default page)
						name: 'text', // needs to correspond to property field of object,
						displayOptions: ['search'],
						searchable: true
					},
					label: 'Text' // column header on list page and label in edit/create form
				},
				{
					formField: { name: 'imageId', type: 'ImageSelectorInput' },
					displayField: {
						name: 'image.fullpath',
						displayAs: 'image-thumb'
					},
					label: 'Image'
				},
				{
					formField: {
						name: 'solutions.id',
						type: 'SolutionsInput'
					},
					displayField: {
						name: 'solutions',
						displayAs: (solutions, problem) => {
							return {
								template: `<div><div v-for="solution in solutions" :key="solution.id"><strong>{{getSolutionType(solution.order)}}</strong> - {{solution.text}}</div></div>`,
								data() {
									return {
										solutions: solutions,
										activityId: problem.activityId
									};
								},
								methods: {
									getSolutionType(order) {
										const mappings =
											this.activityId === 31 ? pictureProblemSolutionMappings : dailyProblemSolutionMappings;
										return mappings.find((mapping) => {
											return mapping.value == order;
										}).label;
									}
								}
							};
						}
					},
					label: 'Solutions'
				},
				{
					formField: {
						// used to define edit and create fields
						name: 'category', // needs to correspond to property field of object
						type: 'TextInput' // all possible inputs: TextInput,MultiLineTextInput,NumberInput,PasswordInput,SelectInput,RadiosInput,YesNoInput,FileInput,ImageFileInput,AudioFileInput,VideoFileInput,TagInput
					},
					displayField: {
						// used to define list view fields (default page)
						name: 'category', // needs to correspond to property field of object
						displayOptions: ['filter'], // optional features of the field, all possible options: search, sort, filter
						filter: {
							options: [
								'Education',
								'Finance',
								'Food and  Drink',
								'Health',
								'Household',
								'Hygiene',
								'Leisure',
								'Safety',
								'Social',
								'Technology',
								'Transportation',
								'Vocational'
							]
						},
						filterable: true
					},
					label: 'Category' // column header on list page and label in edit/create form
				},
				{
					displayField: {
						name: 'type',
						displayOptions: ['sort'],
						sortable: true
					},
					label: 'Type'
				}
			]
		},
		solutions: {
			// service definition - needs to be a unique service name
			label: 'Solutions', // plural service label - can be any text - can be seen on the admin dashboard, navigation
			singularLabel: 'Solution', // singular version of plural service label - can be any text - can be seen on pages when creating or editing
			adminPath: 'solutions', // url slug for the admin panel, eg:http://localhost:8080/admin/problems
			apiPath: 'solutions', // api url, eg:http://localhost:3030/problems
			idField: 'id', // needs to correspond with the id field of the model, eg. for Mongo service it would be '_id', for Sequelize services, it's always 'id'
			displayAs: 'table', // doesn't do anything now, always set as 'table'
			disallowCreate: true,
			disallowEdit: true,
			disallowDelete: true,
			disallowMultiEdit: true,
			fields: [
				// array of fields corresponding to both the list page as well as the edit/create page
				{
					formField: {
						// used to define edit and create fields
						name: 'text', // needs to correspond to property field of object
						type: 'TextInput', // all possible inputs: TextInput,MultiLineTextInput,NumberInput,PasswordInput,SelectInput,RadiosInput,YesNoInput,FileInput,ImageFileInput,AudioFileInput,VideoFileInput,TagInput
						props: { required: true } // additional props that get passed into the Form plugin
					},
					displayField: {
						// used to define list view fields (default page)
						name: 'text' // needs to correspond to property field of object
					},
					label: 'Text' // column header on list page and label in edit/create form
				},
				{
					formField: {
						// used to define edit and create fields
						name: 'order', // needs to correspond to property field of object
						type: 'SolutionTypeInput', // all possible inputs: TextInput,MultiLineTextInput,NumberInput,PasswordInput,SelectInput,RadiosInput,YesNoInput,FileInput,ImageFileInput,AudioFileInput,VideoFileInput,TagInput
						props: {
							required: true
						}
					},
					displayField: {
						// used to define list view fields (default page)
						name: 'order' // needs to correspond to property field of object
					},
					label: 'Type' // column header on list page and label in edit/create form
				},
				{
					// we have this field so that we can access the problemId in the order field to determine what solution options to show
					formField: {
						name: 'problemId', // needs to correspond to property field of object
						type: 'HiddenInput' // all possible inputs: TextInput,MultiLineTextInput,NumberInput,PasswordInput,SelectInput,RadiosInput,YesNoInput,FileInput,ImageFileInput,AudioFileInput,VideoFileInput,TagInput
					},
					displayField: {
						name: 'problemId' // needs to correspond to property field of object
					},
					label: 'Problem Id' // column header on list page and label in edit/create form
				},
				{
					formField: { name: 'imageId', type: 'ImageSelectorInput' },
					label: 'Image'
				}
			]
		},
		coca: {
			label: 'Coca Words',
			singularLabel: 'Coca Word',
			adminPath: 'coca',
			apiPath: 'coca-db',
			idField: '_id',
			displayAs: 'table',
			disallowCreate: true,
			disallowEdit: true,
			disallowDelete: true,
			customItemComponents: [defineAsyncComponent(() => import('@/components/admin/CocaImportChecker.vue'))],
			fields: [
				{
					displayField: { name: 'word', displayOptions: ['sort', 'search'], sortable: true, searchable: true },
					label: 'Word'
				},
				{
					displayField: {
						name: 'PoS',
						displayOptions: ['filter'],
						props: {
							options: [
								'adjective',
								'adverb',
								'article',
								'conjunction',
								'determiner',
								'existential there',
								'marker',
								'interjection',
								'negative',
								'noun',
								'number',
								'preposition',
								'pronoun',
								'verb'
							]
						},
						filterable: true
					},
					label: 'PoS'
				},
				{
					displayField: { name: 'wordRank', displayAs: 'number', displayOptions: ['sort'], sortable: true },
					label: 'Word Rank'
				},
				{
					displayField: { name: 'wordFreq', displayAs: 'number', displayOptions: ['sort'], sortable: true },
					label: 'Word Frequency'
				},
				{
					displayField: { name: 'lemma', displayOptions: ['sort'], soratble: true },
					label: 'Lemma'
				},
				{
					displayField: { name: 'lemRank', displayAs: 'number', displayOptions: ['sort'], sortable: true },
					label: 'Lemma Rank'
				},
				{
					displayField: { name: 'lemFreq', displayAs: 'number', displayOptions: ['sort'], sortable: true },
					label: 'Lemma Frequency'
				},
				{
					displayField: { name: 'percentCaps', displayAs: 'number', displayOptions: ['sort'], sortable: true },
					label: '% Caps'
				},
				{
					displayField: { name: 'percentAllCaps', displayAs: 'number', displayOptions: ['sort'], sortable: true },
					label: '% All Caps'
				}
			]
		}
	}
});

// 		'lesson-pix': {
// 			label: 'LessonPix Words',
// 			singularLabel: 'LessonPix Word',
// 			adminPath: 'lesson-pix',
// 			apiPath: 'lesson-pix',
// 			idField: '_id',
// 			displayAs: 'table',
// 			disallowCreate: true,
// 			disallowEdit: true,
// 			disallowDelete: true,
// 			fields: [
// 				{
// 					displayField: { name: 'word', displayOptions: ['sort', 'search'], sortable: true, searchable: true },
// 					label: 'Word'
// 				},
// 				{
// 					displayField: { name: 'stem', displayOptions: ['sort'], sortable: true },
// 					label: 'Stem'
// 				},
// 				{
// 					displayField: { name: 'soundex', displayOptions: ['sort'], sortable: true },
// 					label: 'Soundex'
// 				},
// 				{
// 					displayField: { name: 'pronounced', displayOptions: ['sort'], sortable: true },
// 					label: 'Pronounced'
// 				},
// 				{
// 					displayField: { name: 'rhymes_with', displayOptions: ['sort'], sortable: true },
// 					label: 'Rhymes With'
// 				},
// 				{
// 					displayField: { name: 'popularity', displayAs: 'number', displayOptions: ['sort'], sortable: true },
// 					label: 'Popularity'
// 				},
// 				{
// 					displayField: { name: 'frequency', displayAs: 'number', displayOptions: ['sort'], sortable: true },
// 					label: 'Frequency'
// 				},
// 				{
// 					displayField: { name: 'soundpattern', displayOptions: ['sort'], sortable: true },
// 					label: 'Sound Pattern'
// 				},
// 				{
// 					displayField: { name: 'noiseword', displayOptions: ['sort'], sortable: true },
// 					label: 'Noise Word'
// 				},
// 				{
// 					displayField: { name: 'orig_ipa', displayOptions: ['sort'], sortable: true },
// 					label: 'Original IPA'
// 				},
// 				{
// 					displayField: { name: 'clean_word', displayOptions: ['sort'], sortable: true },
// 					label: 'Clean Word'
// 				},
// 				{
// 					displayField: { name: 'soundstring', displayOptions: ['sort'], sortable: true },
// 					label: 'Sound String'
// 				},
// 				{
// 					displayField: { name: 'syllables', displayAs: 'number', displayOptions: ['sort'], sortable: true },
// 					label: 'Syllables'
// 				}
// 			]
// 		},
// 		resemble: {
// 			label: 'Resemble Words',
// 			singularLabel: 'Resemble Word',
// 			adminPath: 'resemble',
// 			apiPath: 'resemble-data',
// 			idField: '_id',
// 			displayAs: 'table',
// 			disallowCreate: true,
// 			disallowEdit: true,
// 			disallowDelete: true,
// 			fields: [
// 				{
// 					displayField: { name: 'word', displayOptions: ['sort', 'search'], sortable: true, searchable: true },
// 					label: 'Word'
// 				},
// 				{
// 					displayField: { name: 'transcription', displayOptions: ['sort'], sortable: true },
// 					label: 'Transcription'
// 				}
// 			]
// 		},
// 		'words-api': {
// 			label: 'WordsAPI Words',
// 			singularLabel: 'WordsAPI Word',
// 			adminPath: 'words-api',
// 			apiPath: 'words-api',
// 			idField: '_id',
// 			displayAs: 'table',
// 			disallowCreate: true,
// 			disallowEdit: true,
// 			disallowDelete: true,
// 			fields: [
// 				{
// 					displayField: { name: 'word', displayOptions: ['sort', 'search'], sortable: true, searchable: true },
// 					label: 'Word'
// 				},
// 				{
// 					displayField: { name: 'syllables.count', displayAs: 'number', displayOptions: ['sort'], sortable: true },
// 					label: 'Syllables'
// 				},
// 				{
// 					displayField: { name: 'letters', displayAs: 'number', displayOptions: ['sort'], sortable: true },
// 					label: 'Letters'
// 				},
// 				{
// 					displayField: { name: 'sounds', displayAs: 'number', displayOptions: ['sort'], sortable: true },
// 					label: 'Sounds'
// 				},
// 				{
// 					displayField: { name: 'pronunciation.all', displayOptions: [] },
// 					label: 'Pronunciation'
// 				},
// 				{
// 					displayField: { name: 'rhymePatterns.all', displayOptions: [] },
// 					label: 'Rhyme Patterns'
// 				},
// 				{
// 					displayField: { name: 'frequency.perMillion', displayAs: 'number', displayOptions: ['sort'], sortable: true },
// 					label: 'Frequency'
// 				}
// 			]
// 		},
// 		'word-net': {
// 			label: 'WordNet Definitions',
// 			singularLabel: 'WordNet Definition',
// 			adminPath: 'word-net',
// 			apiPath: 'word-net',
// 			idField: '_id',
// 			displayAs: 'table',
// 			showId: false,
// 			disallowCreate: true,
// 			disallowEdit: true,
// 			disallowDelete: true,
// 			defaultSortField: ['PoS', 'order'],

// 			fields: [
// 				{
// 					displayField: { name: 'definition._id', displayOptions: [] },
// 					label: 'ID'
// 				},
// 				{
// 					displayField: { name: 'word', displayOptions: ['sort', 'search'], sortable: true, searchable: true },
// 					label: 'Word'
// 				},
// 				{
// 					displayField: { name: 'PoS', displayOptions: [] },
// 					label: 'PoS'
// 				},
// 				{
// 					displayField: {
// 						name: 'definition',
// 						displayAs: (definition, item) => {
// 							return {
// 								template: `<div><div><strong>"{{definition.definition}}"</strong></div><div>SemanticGroup: {{definition.semanticGroup}}</div><div v-if="filteredWords.length">Synonyms: <span v-for="(word, index) in filteredWords" :key="word.id">{{word.text}}{{ comma(index, filteredWords.length) }}</span></template></div><div v-if="definition.examples.length">Example Sentences:<br/><ul><li v-for="ex in definition.examples">{{ex}}</li></ul></div></div>`,
// 								data() {
// 									return {
// 										definition: definition,
// 										word: item.word
// 									};
// 								},
// 								computed: {
// 									filteredWords() {
// 										return this.definition.words.filter((word) => word.text.toLowerCase() != this.word);
// 									}
// 								},
// 								methods: {
// 									comma(index, length) {
// 										return index < length - 1 ? ', ' : '';
// 									}
// 								},
// 								created() {
// 									console.log(this.$parent);
// 								}
// 							};
// 						},
// 						displayOptions: []
// 					},
// 					label: 'Definition'
// 				}
// 			]
// 		}
// 	}
// });

const auth = getServiceStore('auth');

auth
	.authenticate()
	.catch((err) => {
		if (err.name == 'NotFound') auth.logout();
		if (err.code == 408) {
			console.error('Initial Authentication Timeout - ' + err.message + ' - strategy:' + err.data.strategy);
		}
	})
	// Render the app
	.then(() => {
		// allow access to vue app object from cypress
		if (window.Cypress) {
			const mediaPlaybackRate = window.Cypress.env('mediaPlaybackRate');
			const mediaMuted = window.Cypress.env('mediaMuted');

			if (mediaPlaybackRate) {
				app._context.components.Audio.props.playbackRate.default = mediaPlaybackRate;
				app._context.components.Video.props.playbackRate.default = mediaPlaybackRate;
			}
			if (mediaMuted) {
				app._context.components.Audio.props.muted.default = true;
				app._context.components.Video.props.muted.default = true;
			}

			window.app = app;
			window.getServiceStore = getServiceStore;
		}

		mixpanel.init();

		app.use(router);
		app.mount('#app_wrapper');
	});
