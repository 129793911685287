const dailyProblemSolutionMappings = [
	{ label: 'Best', value: 0 },
	{ label: 'Acceptable', value: 1 },
	{ label: 'Poor', value: 2 },
	{ label: 'Worst', value: 3 }
];

const pictureProblemSolutionMappings = [
	{ label: 'Solution', value: 0 },
	{ label: 'Semantic', value: 1 },
	{ label: 'Visual', value: 2 },
	{ label: 'Related to Problem', value: 3 }
];

export { dailyProblemSolutionMappings, pictureProblemSolutionMappings };
