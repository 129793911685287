<template>
	<div id="maintenance" :style="{ order: 0, height: maintenance.bannerHeight + 'px' }">
		<Fade :duration="300">
			<div v-if="showBanner" id="maintenance-banner" ref="banner">
				<template v-if="showNotificationBanner">
					<BaseButton class="close-btn" size="lg"><Icon icon="close" @click="handleDiscard" /></BaseButton>
					<p><strong>Scheduled Maintenance</strong></p>
					<p>
						The Virtual Rehab Center may be unavailable
						<template v-if="isSameDay">
							on <strong>{{ maintenance.currentStart.format('ddd, MMM D') }}</strong> from
							<strong>{{ maintenance.currentStart.format('h:mm a zz') }}</strong> until
							<strong>{{ maintenance.currentEnd.format('h:mm a zz') }}</strong>
						</template>
						<template v-else>
							from <strong>{{ maintenance.currentStart.format('ddd, MMM D') }}</strong> at
							<strong>{{ maintenance.currentStart.format('h:mm a zz') }}</strong> until
							<strong>{{ maintenance.currentEnd.format('ddd, MMM D') }}</strong> at
							<strong>{{ maintenance.currentEnd.format('h:mm a zz') }}</strong> </template
						>.
					</p>
				</template>
				<template v-if="showActiveBanner">
					<p>The Virtual Rehab Center is currently <strong>unavailable</strong>.</p>
				</template>
			</div>
		</Fade>
		<div
			v-if="maintenance.isActive && hasMaintenanceAccess && $route.name != 'Maintenance'"
			id="maintenance-flag"
		></div>
	</div>
</template>

<script>
import { getServiceStore } from '@/plugins/FeathersAPI';
import moment from 'moment';
import Icon from './Icon.vue';
import BaseButton from './buttons/BaseButton.vue';
import Fade from '@/components/transitions/Fade.vue';

export default {
	name: 'MaintenanceBanner',
	components: { Icon, BaseButton, Fade },
	data() {
		return {
			observer: undefined
		};
	},
	computed: {
		maintenance() {
			return getServiceStore('maintenance');
		},
		user() {
			return getServiceStore('auth').user;
		},
		hasMaintenanceAccess() {
			return this.user && this.user.fullPermissionsList.includes('maintenance');
		},
		wrapperClasses() {
			return { animate: this.animate };
		},
		showBanner() {
			return this.showNotificationBanner || this.showActiveBanner;
		},
		showNotificationBanner() {
			return (
				!this.maintenance.discarded &&
				this.maintenance.shouldNotify &&
				!this.maintenance.isActive &&
				!['Exercise', 'Maintenance'].includes(this.$route.name)
			);
		},
		showActiveBanner() {
			return this.maintenance.isActive && !this.user;
		},
		isSameDay() {
			return this.maintenance.currentStart.isSame(this.maintenance.currentEnd, 'day');
		}
	},
	watch: {
		showBanner(val) {
			if (val) {
				this.$nextTick(() => {
					this.observeBanner();
				});
			} else {
				this.maintenance.bannerHeight = 0;
				if (this.observer) this.observer.disconnect();
			}
		},
		'maintenance.currentState'(val, oldVal) {
			// if changing away from active maintenance and user is not on an admin edit screen, force refresh
			if (oldVal == 'active' && this.$route.name != 'AdminEditItem') {
				window.location.reload();
				return;
			}
			const redirect = this.maintenance.checkRoute(this.$route, this.user);
			if (redirect) this.$router.push(redirect);
		}
	},
	created() {
		const discardedDay = window.localStorage.getItem('discardedMaintenanceBanner');
		if (discardedDay && moment().isSame(discardedDay, 'day')) this.maintenance.discarded = true;
	},
	mounted() {
		if (this.$refs.banner) this.observeBanner();
	},
	unmount() {
		this.stopObserving();
	},
	methods: {
		observeBanner() {
			this.observer = new ResizeObserver((entries) => {
				this.maintenance.bannerHeight = entries[0].target.offsetHeight;
			});
			this.observer.observe(this.$refs.banner);
		},
		stopObserving() {
			if (this.observer) this.observer.disconnect();
		},
		handleDiscard() {
			this.maintenance.discarded = true;
			window.localStorage.setItem('discardedMaintenanceBanner', moment().format());
		}
	}
};
</script>
<style lang="scss">
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/functions.scss';

#maintenance {
	transition: height 0.3s;
	#maintenance-banner {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 10;
		padding: rem(8) rem(12);
		background-color: $orange;
		font-size: rem(12);
		text-align: center;

		p:first-of-type {
			font-size: rem(14);
		}

		@media (min-width: 768px) {
			padding-left: rem(40);
			padding-right: rem(40);
			font-size: rem(13);
			p:first-of-type {
				font-size: rem(14);
			}
		}
		.close-btn {
			position: absolute;
			top: 0px;
			right: 0px;
			background-color: transparent;
			border-color: transparent;
			color: $text-primary;
			padding: rem(5);
			margin-bottom: 0;
			@media (min-width: 768px) {
				// top: 50%;
				// right: 8px;
				// transform: translateY(-50%);
				.tactus-icon {
					font-size: 26px;
				}
			}
		}
		p {
			margin-bottom: 0;
		}
	}
	#maintenance-flag {
		position: fixed;
		inset: 0;
		// highest z-index
		z-index: 2147483647;
		border: 4px solid $orange;
		pointer-events: none;

		@media (min-width: 768px) {
			border-width: 8px;
		}
	}
}
#admin + #maintenance #maintenance-banner {
	top: rem(45);
}
.side-nav-page + #maintenance #maintenance-banner {
	top: rem(45);
	@media (min-width: 768px) {
		left: rem(160);
		top: 0;
	}
}
</style>
