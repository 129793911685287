import { defineAsyncComponent, h, render } from 'vue';
import mitt from 'mitt';
import ModalManager from './components/ModalManager.vue';
import { every } from 'lodash';

// Modals Plugin
const PLUGIN_NAME = 'ModalsPlugin';
const VERSION = '1.0.0';

const DEFAULT_OPTIONS = {};

const ModalsPlugin = {
	install(app, userOptions) {
		// eslint-disable-next-line no-unused-vars
		let options = { ...DEFAULT_OPTIONS, ...userOptions };

		// set up plugins registry if it doesn't exist
		if (typeof app.config.globalProperties.$plugins == 'undefined') {
			app.config.globalProperties.$plugins = {};
		}
		// register plugin with plugins registry
		app.config.globalProperties.$plugins[PLUGIN_NAME] = VERSION;

		// Create the plugin's root Vue instance
		const manager = h(ModalManager);
		manager.appContext = app._context;

		// register 'Modal' component
		app.component(
			'Modal',
			defineAsyncComponent(() => import('./components/Modal.vue'))
		);

		const eventBus = mitt();
		const modals = {};

		const registrationFunctions = {
			get(name) {
				if (typeof name != 'undefined') return modals[name];
				else return modals;
			},
			add(modal) {
				modals[modal.name] = modal;
				if (modal.showOnLoad) modalFunctions.show(modal.name);
			},
			remove(name) {
				delete modals[name];
				if (every(modals, { isVisible: false })) {
					eventBus.emit('reset');
				}
			}
		};
		const modalFunctions = {
			show(name) {
				eventBus.emit('show', { name });
			},
			hide(name) {
				eventBus.emit('hide', { name });
			},
			exists(name) {
				return modals[name] ? true : false;
			},
			create(name, props) {
				eventBus.emit('create', { name, props });
			},
			destroy(name) {
				eventBus.emit('destroy', { name });
			},
			cloneComponent(name, component, props, componentProps) {
				eventBus.emit('cloneComponent', { name, component, props, componentProps });
			},
			uniqueId() {
				return Date.now() + '.' + Math.floor(Math.random() * 999) + '.' + Math.floor(Math.random() * 999);
			},
			manager: eventBus
		};

		app.provide('$modals', modalFunctions);
		app.provide('$modalRegistry', registrationFunctions);

		// Mount the plugin's root Vue instance on a new div element added to body
		const el = document.createElement('div');
		el.setAttribute('id', 'modal_manager');
		render(manager, document.body.appendChild(el));
	}
};
export { ModalsPlugin as default };
