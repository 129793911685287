import { defineAsyncComponent } from 'vue';
import mitt from 'mitt';

// Modals Plugin
const PLUGIN_NAME = 'PopoverPlugin';
const VERSION = '1.0.0';

const DEFAULT_OPTIONS = {};

const PopoverPlugin = {
	install(app, userOptions) {
		// eslint-disable-next-line no-unused-vars
		let options = { ...DEFAULT_OPTIONS, ...userOptions };

		// set up plugins registry if it doesn't exist
		if (typeof app.config.globalProperties.$plugins == 'undefined') {
			app.config.globalProperties.$plugins = {};
		}
		// register plugin with plugins registry
		app.config.globalProperties.$plugins[PLUGIN_NAME] = VERSION;

		// register 'Popover' component globally
		app.component(
			'Popover',
			defineAsyncComponent(() => import('./components/Popover.vue'))
		);

		const eventBus = mitt();
		const popovers = [];

		eventBus.on('add', (popover) => {
			popovers[popover.name] = popover;
		});
		eventBus.on('remove', (popover) => {
			delete popovers[popover.name];
		});
		eventBus.on('show', ({ name, trigger }) => {
			if (Object.hasOwnProperty.call(popovers, name)) {
				popovers[name].setPosition(trigger);
			}
		});
		eventBus.on('hide', (name) => {
			if (Object.hasOwnProperty.call(popovers, name)) {
				popovers[name].hide();
			}
		});

		const popoverFunctions = {
			show(name, trigger) {
				eventBus.emit('show', { name, trigger });
			},
			hide(name) {
				eventBus.emit('hide', name);
			},
			uniqueId() {
				return Date.now() + '.' + Math.floor(Math.random() * 999) + '.' + Math.floor(Math.random() * 999);
			},
			manager: eventBus
		};

		app.provide('$popovers', popoverFunctions);
	}
};

export { PopoverPlugin as default };
