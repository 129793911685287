const onFocusMixin = (fns) => {
	fns = fns && !Array.isArray(fns) ? [fns] : fns;

	return {
		data() {
			// root Component maintains the array of onFocus handlers and the isFocused state
			return this == this.$root
				? {
						isFocused: false,
						onFocus: [],
						registeredFocusFns: []
				  }
				: {
						registeredFocusFns: []
				  };
		},
		created() {
			if (fns) {
				// each function can either be an actual function, or name of a function on the component
				fns.forEach((fn) => {
					// if its a function, register an anonymous function to executes the passed in function so we can pass in the component
					if (typeof fn == 'function') {
						this.registeredFocusFns.push(() => fn(this));
					}
					// if its a string, we can register the actual function as it already has the component context
					else if (typeof fn == 'string') {
						if (typeof this[fn] == 'function') this.registeredFocusFns.push(this[fn]);
						else console.warn(`onFocusMixin - ${fn} is not a registered method in ${this.$options.__file}`);
					} else {
						console.warn(`onFocusMixin - Invalid argument passed from ${this.$options.__file}`);
					}
				});
				this.registeredFocusFns.forEach((fn) => this.$root.onFocus.push(fn));
			}

			if (this !== this.$root) return;

			// root Component sets up needed listeners focus and blur events
			this.handleFocus = () => {
				this.callFocusHandlersOnce();
			};
			this.handleBlur = () => {
				this.isFocused = false;
			};
			this.handleVisibilityChange = (e) => {
				if (e.target.visibilityState == 'visible') this.callFocusHandlersOnce();
				else this.isFocused = false;
			};
			this.callFocusHandlersOnce = () => {
				// if already isFocused no need to call handlers
				if (this.isFocused) return;
				this.isFocused = true;
				this.callFocusHandlers();
			};
			this.callFocusHandlers = () => {
				this.$root.onFocus.forEach((fn) => fn());
			};
			window.addEventListener('focus', this.handleFocus);
			window.addEventListener('blur', this.handleBlur);
			window.addEventListener('visibilitychange', this.handleVisibilityChange);
		},
		mounted() {
			if (this !== this.$root) return;

			if (document.visibilityState == 'visible') this.handleFocus();
		},
		unmounted() {
			if (fns) {
				this.registeredFocusFns.forEach((fn) => {
					const fnIndex = this.$root.onFocus.indexOf(fn);
					if (fnIndex !== -1) this.$root.onFocus.splice(fnIndex, 1);
					else console.warn(`onFocusMixin - a function cannot be unregistered from ${this.$options.__file}`);
				});
			}

			if (this !== this.$root) return;

			// root Component removes registered event listeners
			window.removeEventListener('focus', this.handleFocus);
			window.removeEventListener('blur', this.handleBlur);
			window.removeEventListener('visibilitychange', this.handleVisibilityChange);
		}
	};
};
export default onFocusMixin;
