<template>
	<Form
		v-model="formData"
		:fields="getFormFields('reAuth')"
		submit-text="Log In"
		include-cancel
		@submit="handleLogin"
		@cancel="handleCancel"
	/>
</template>

<script>
import { markRaw } from 'vue';
import { getServiceStore } from '@/plugins/FeathersAPI';
import { mapState } from 'pinia';
import { usePluginStore } from '../';

export default {
	name: 'ReAuthForm',
	props: {
		email: {
			type: String,
			required: true
		}
	},
	emits: ['success', 'error', 'cancel'],
	data() {
		return {
			formData: { email: this.email }
		};
	},
	computed: {
		...mapState(usePluginStore, ['getFormFields'])
	},
	methods: {
		handleLogin(showSuccess, showError) {
			getServiceStore('auth')
				.authenticate({ strategy: 'local', ...this.formData })
				.then(() => {
					showSuccess().then(() => {
						this.$emit('success');
					});
				})
				.catch((error) => {
					if (error.code == 408) {
						console.error('ReAuth Timeout - ' + error.message + ' - strategy:' + error.data.strategy);
					}
					const errorContent = error ? markRaw({ template: `<p>${error.message}</p>` }) : undefined;
					showError(errorContent);
					this.$emit('error');
				});
		},
		handleCancel() {
			this.$emit('cancel');
		},
		focusFirstInput() {
			this.$el.elements[0].focus();
		}
	}
};
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';

#re-auth {
	::placeholder {
		opacity: 0.3;
	}

	.btn-row {
		[type='button'] {
			font-weight: 500;
			color: $grey-1;
			background-color: $grey-4;
			border: none;
			width: 100%;
			@media (hover: hover) {
				&:hover {
					background-color: mix($white, $grey-4, 25%);
				}
			}
		}

		[type='submit'] {
			font-weight: 500;
			background-color: $secondary-2;
			border: none;
			width: 100%;
			@media (hover: hover) {
				&:hover {
					background-color: mix($white, $secondary-2, 25%);
				}
			}
		}
	}
}
</style>
