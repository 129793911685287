export default {
	servicePath: 'auth-management',
	modelName: false,
	actions: {
		async inviteUser(data, params = {}) {
			return this.create({ action: 'invite-user', value: data }, params);
		},
		createAccount(data, params = {}) {
			return this.create({ action: 'create-account', value: data }, params);
		},
		activateAccount(data, params = {}) {
			return this.create({ action: 'activate-account', value: data }, params);
		},
		verifyEmail(data, params = {}) {
			return this.create({ action: 'verify-email', value: data }, params);
		},
		resendVerification(data, params = {}) {
			return this.create({ action: 'resend-verification', value: data }, params);
		},
		changeIdentity(data, params = {}) {
			return this.create({ action: 'change-identity', value: data }, params);
		},
		revertIdentity(data, params = {}) {
			return this.create({ action: 'revert-identity', value: data }, params);
		},
		async changePassword(data, params = {}) {
			return this.create({ action: 'change-password', value: data }, params);
		},
		forgotPassword(data, params = {}) {
			return this.create({ action: 'forgot-password', value: data }, params);
		},
		resetPassword(data, params = {}) {
			return this.create({ action: 'reset-password', value: data }, params);
		},
		validateToken(data, params = {}) {
			return this.create({ action: 'validate-token', value: data }, params);
		}
	}
};
