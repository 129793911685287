import { defineAsyncComponent } from 'vue';
import { has, reduce } from 'lodash';
import permissionsMiddleware from './middleware/hasPermissions';
import permissionsMixin from './mixins/permissions';
const ServiceHeading = defineAsyncComponent(() => import('./components/ServiceHeading.vue'));
const CreateItemForm = defineAsyncComponent(() => import('./components/CreateItemForm.vue'));
const EditItemForm = defineAsyncComponent(() => import('./components/EditItemForm.vue'));

// Admin Plugin
const PLUGIN_NAME = 'AdminPlugin';
const VERSION = '1.0.0';

const DEFAULT_OPTIONS = {
	adminPath: '/admin',
	middleware: [],
	navComponent: undefined,
	globalServiceIncludes: [],
	services: {
		// EXAMPLE/DOCUMENTATION
		// problems: {
		// 	// service definition - needs to be a unique service name
		// 	label: 'Problems', // plural service label - can be any text - can be seen on the admin dashboard, navigation
		// 	singularLabel: 'Problem', // singular version of plural service label - can be any text - can be seen on pages when creating or editing
		// 	adminPath: 'problems', // url slug for the admin panel, eg:http://localhost:8080/admin/problems
		// 	apiPath: 'problems', // api url, eg:http://localhost:3030/problems
		// 	idField: 'id', // needs to correspond with the id field of the model, eg. for Mongo service it would be '_id', for Sequelize services, it's always 'id'
		// 	includes: ['audio', 'activity', 'solutions'], // needs to correspond with model association names
		// 	displayAs: 'table', // doesn't do anything now, always set as 'table'
		// 	disallowCreate: true, // disables on the ability to create items
		// 	disallowEdit: true, // disables on the ability to edit items
		// 	disallowDelete: true, // disables on the ability to delete items
		// 	disallowMultiEdit: true, // by default, multiEdit is enabled
		// 	defaultSortField: ['activityId', 'category'], // array of sort fields, when defaultSortField is not defined, default sort is by id
		// 	defaultSortOrder: [-1], // default sort order is 1, which is ascending order
		// 	fields: [
		// 		// array of fields corresponding to both the list page as well as the edit/create page
		// 		{
		// 			formField: {
		// 				// used to define edit and create fields
		// 				name: 'text', // needs to correspond to property field of object
		// 				type: 'TextInput', // all possible inputs: TextInput,MultiLineTextInput,NumberInput,PasswordInput,SelectInput,RadiosInput,YesNoInput,FileInput,ImageFileInput,AudioFileInput,VideoFileInput,TagInput,SolutionTypeInput,ActivitiesInput
		// 				props: { required: true } // additional props that get passed into the Form plugin
		// 			},
		// 			displayField: {
		// 				// used to define list view fields (default page)
		// 				name: 'text', // needs to correspond to property field of object
		// 				displayAs: 'text', // how you want this field to be displayed, all possible options: datetime, relative-datetime, number, yes-no, true-false, filesize, list, count, image, image-list, image-thumb, image-thumb-list, image-sizes, audio, video
		// 				displayOptions: ['sort', 'search'], // optional features of the field, all possible options: search, sort, filter
		// 				multiEdit: false, // if the service has multiEdit, this lets you disable a field
		// 				// TODO remove props
		// 				sortable: true, // corresponds with displayOptionsArray, all possible options: searchable, sortable, filterable
		// 				searchable: true
		// 			},
		// 			label: 'Text' // column header on list page and label in edit/create form
		// 		}
		// 	]
		// }
	},
	customRoutes: []
};

const adminDataMixin = {};

const AdminPlugin = {
	install(app, userOptions = {}) {
		// check for dependencies
		if (app.config.globalProperties.$plugins && !app.config.globalProperties.$plugins['FormPlugin']) {
			console.error(`${PLUGIN_NAME}:${VERSION} - requires FormPlugin to be installed`);
		}
		if (app.config.globalProperties.$plugins && !app.config.globalProperties.$plugins['AuthenticationPlugin']) {
			console.error(`${PLUGIN_NAME}:${VERSION} - requires AuthenticationPlugin to be installed`);
		}

		if (typeof userOptions.router == 'undefined') {
			console.error(`${PLUGIN_NAME}:${VERSION} - options requires a 'router' property`);
		}
		if (!(typeof userOptions.router == 'object' && Object.hasOwnProperty.call(userOptions.router, 'addRoute'))) {
			console.error(`${PLUGIN_NAME}:${VERSION} - options 'router' property must reference a VueRouter`);
		}

		let options = { ...DEFAULT_OPTIONS, ...userOptions };

		// override disallowMultiEdit option to completely turnoff multiEdit feature
		options.disallowMultiEdit = true;
		// override includeRevisions option to completely turnoff revisions feature
		options.includeRevisions = false;

		// set up plugins registry if it doesn't exist
		if (!has(app.config.globalProperties, '$plugins')) {
			app.config.globalProperties.$plugins = {};
		}
		// register plugin with plugins registry
		app.config.globalProperties.$plugins[PLUGIN_NAME] = VERSION;

		const services = reduce(
			options.services,
			(result, service, key) => {
				// override disallowMultiEdit option to completely turnoff multiEdit feature
				service.disallowMultiEdit = true;
				// override includeRevisions option to completely turnoff revisions feature
				service.includeRevisions = false;

				let includes = service.includes || [];
				if (!service.excludeGlobalIncludes) includes = [...includes, ...options.globalServiceIncludes];
				if (includes.length > 0) service.includes = includes;

				result[key] = service;
				return result;
			},
			{}
		);

		adminDataMixin.computed = {
			adminPath() {
				return options.adminPath;
			},
			services() {
				return services;
			}
		};

		const parseCustomRoutes = (routes = []) => {
			return routes.map((route) => {
				return {
					path: route.path,
					name: route.name,
					component: route.component,
					children: parseCustomRoutes(route.children),
					meta: {
						middleware: [...options.middleware, permissionsMiddleware],
						permissions: ($route) => (route.meta && route.meta.permissions ? [...route.meta.permissions($route)] : [])
					}
				};
			});
		};

		userOptions.router.addRoute({
			path: options.adminPath,
			component: () => import('./views/Admin.vue'),
			props: {
				navComponent: options.navComponent ? options.navComponent : undefined
			},
			children: [
				{
					path: '',
					name: 'Admin',
					component: () => import('./views/Services.vue'),
					meta: {
						middleware: [...options.middleware, permissionsMiddleware],
						permissions: () => ['admin-dashboard']
					}
				},
				...parseCustomRoutes(options.customRoutes),
				{
					path: ':service',
					name: 'AdminItemsList',
					component: () => import('./views/ItemsList.vue'),
					meta: {
						middleware: [...options.middleware, permissionsMiddleware],
						permissions: ($route) => ['admin-' + $route.params.service, 'admin-' + $route.params.service + '-read']
					}
				},
				{
					path: ':service/create',
					name: 'AdminCreateItem',
					component: () => import('./views/CreateItem.vue'),
					meta: {
						middleware: [...options.middleware, permissionsMiddleware],
						permissions: ($route) => ['admin-' + $route.params.service, 'admin-' + $route.params.service + '-edit']
					}
				},
				{
					path: ':service/import',
					name: 'AdminImportItems',
					component: () => import('./views/ImportItems.vue'),
					meta: {
						middleware: [...options.middleware, permissionsMiddleware],
						permissions: ($route) => ['admin-' + $route.params.service + '-import']
					}
				},
				{
					path: ':service/:id/edit',
					name: 'AdminEditItem',
					component: () => import('./views/EditItem.vue'),
					meta: {
						middleware: [...options.middleware, permissionsMiddleware],
						permissions: ($route) => ['admin-' + $route.params.service, 'admin-' + $route.params.service + '-edit']
					}
				},
				{
					path: ':service/:id/revision/:revision',
					name: 'AdminItemRevision',
					component: () => import('./views/ItemRevision.vue'),
					meta: {
						middleware: [...options.middleware, permissionsMiddleware],
						permissions: ($route) => ['admin-' + $route.params.service, 'admin-' + $route.params.service + '-revision']
					}
				}
			]
		});
	}
};

export {
	AdminPlugin as default,
	ServiceHeading,
	CreateItemForm,
	EditItemForm,
	permissionsMixin,
	adminDataMixin,
	permissionsMiddleware
};
