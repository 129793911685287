export default {
	servicePath: 'goal-short-term-goals',
	modelName: 'GoalShortTermGoal',
	setupInstance(data, { models, defineManyAssociation }) {
		defineManyAssociation(data, 'longTermGoals', models.api.GoalLongTermGoal);
		defineManyAssociation(data, 'dynamicStgGoals', models.api.GoalDynamicStgGoal);

		return data;
	}
};
