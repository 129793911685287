<template>
	<BaseButton :is-disabled="user.fastspringAccountAuth === null" color="primary" size="lg" @click="handleClick"
		><Icon icon="account" /><slot>Manage your account</slot></BaseButton
	>
</template>

<script>
import BaseButton from '@/components/buttons/BaseButton.vue';
import Icon from '@/components/Icon.vue';
import { getServiceStore } from '@/plugins/FeathersAPI';
import moment from 'moment';

export default {
	name: 'FastspringAccountLink',
	components: {
		BaseButton,
		Icon
	},
	emits: ['click'],
	data() {
		return {};
	},
	computed: {
		user() {
			return getServiceStore('auth').user;
		},
		isExpired() {
			return (
				!this.user.fastspringAccountAuth || !moment.utc(this.user.fastspringAccountAuth.expires).isAfter(moment.utc())
			);
		}
	},
	created() {
		if (this.isExpired) this.getFastspringAccountAuth();
	},
	methods: {
		getFastspringAccountAuth() {
			return getServiceStore('fastspring')
				.get('accountAuth')
				.then((data) => {
					this.user.fastspringAccountAuth = data;
				});
		},
		handleClick() {
			if (!this.isExpired) {
				window.open(this.user.fastspringAccountAuth.url, '_blank');
				this.$emit('click');
			} else {
				this.getAuth().then(() => {
					alert('Sorry, that link has expired. Please try again.');
				});
			}
		}
	}
};
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/functions.scss';
</style>
