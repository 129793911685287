<template>
	<video ref="video" :muted="muted" :controls="controls" preload="auto" playsinline>
		<source :src="src + '#t=' + posterFrameTime" type="video/mp4" />
	</video>
</template>

<script>
const DEFAULT_FIRST_FRAME = 0.001;

export default {
	name: 'Video',
	inject: ['$media'],
	props: {
		name: {
			type: String,
			required: true
		},
		src: {
			type: String,
			required: true
		},
		muted: {
			type: Boolean,
			default: undefined
		},
		controls: {
			type: Boolean,
			default: true
		},
		playbackRate: {
			type: Number,
			default: 1
		},
		posterFrameTime: {
			type: Number,
			default: DEFAULT_FIRST_FRAME
		}
	},
	emits: ['registered', 'loaded', 'queued', 'unqueued', 'play', 'pause', 'stop', 'end'],
	mounted() {
		// https://stackoverflow.com/questions/21911459/html5-listener-loadeddata-do-not-work-certain
		// call .load() before adding the event listener for 'loadeddata' because it was not firing on iPhone
		this.$refs.video.load();
		this.$refs.video.addEventListener('loadeddata', this.handleLoadedDataEvent);
		this.$media.manager.emit('register', this);
	},
	beforeUnmount() {
		this.$refs.video.removeEventListener('loadeddata', this.handleLoadedDataEvent);
		this.$media.manager.emit('unregister', this);
	},
	methods: {
		handleLoadedDataEvent() {
			// console.log(
			// 	'handleLoadedDataEvent readystate is',
			// 	this.$refs.video.readyState,
			// 	HTMLMediaElement.HAVE_CURRENT_DATA
			// );
			if (this.$refs.video.readyState >= HTMLMediaElement.HAVE_CURRENT_DATA) {
				this.$emit('loaded');
			}
		},
		handleEndedEvent() {
			this.$emit('end');
			this.showPosterFrame();
			if (this.$refs.video) this.$refs.video.removeEventListener('ended', this.handleEndedEvent);
		},
		play() {
			this.$refs.video.playbackRate = this.playbackRate;

			// when playing the video, start at the beginning of the video
			this.setVideoCurrentTime(DEFAULT_FIRST_FRAME);

			return this.$refs.video
				.play()
				.then(() => {
					this.$emit('play');
					// in case video is paused, and then unpaused, there is no need to check if the ended event listener has already been added
					// before adding it because "If multiple identical EventListeners are registered on the same EventTarget with the same parameters the duplicate instances are discarded"
					// https://www.w3.org/TR/2000/REC-DOM-Level-2-Events-20001113/events.html#Events-EventTarget-addEventListener
					this.$refs.video.addEventListener('ended', this.handleEndedEvent);
					return this.$refs.video;
				})
				.catch((err) => {
					console.error(err);
					return this.$refs.video;
				});
		},
		pause() {
			this.$refs.video.pause();
			this.$emit('pause');
			this.showPosterFrame();
		},
		stop() {
			// video element doesn't have a stop method
			this.pause();
			this.$refs.video.removeEventListener('ended', this.handleEndedEvent);
			this.$emit('stop');
		},
		showPosterFrame() {
			this.setVideoCurrentTime(this.posterFrameTime);
		},
		setVideoCurrentTime(time) {
			if (this.$refs.video) {
				this.$refs.video.currentTime = time;
			}
		}
	}
};
</script>

<style lang="scss"></style>
