import {
	faro,
	initializeFaro,
	ErrorsInstrumentation,
	WebVitalsInstrumentation,
	SessionInstrumentation,
	ViewInstrumentation
} from '@grafana/faro-web-sdk';
import * as opentelemetry from '@opentelemetry/api';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import env from './env';
import { allLogLevels, BaseInstrumentation, LogLevel } from '@grafana/faro-core';

// All functions do nothing if observability is not enabled. All functions are
// safe to call, they will not throw.

// Enable observability by setting the env var ENABLE_OBSERVABILITY to true and
// calling initialize() on app startup before everything you want to observe.

/**
 * This is where our observability data gets sent to. It does not contain a
 * sensitive credential. In Grafana we can set allowed origins for CORS
 * requests to the collector.
 */
const FARO_COLLECTOR_URL = 'https://faro-collector-prod-us-east-0.grafana.net/collect/3252478fcb2a21ad6096c08d4710f509';
// A future improvement would be to define this information elsewhere so we can
// source the information from one place for all of our various integrations.
const APP_NAME = 'web-platform-client';
/**
 * This should be the version of our web client. I think this is currently
 * untracked/maintained.
 */
const APP_VERSION = '1.0.0';
const APP_ENVIRONMENT = env('NODE_ENV') || 'unset';
/** We have to pick which requests we trace by providing an array of urls. */
const CORS_TRACE_URLS = [env('API_URL')];

/**
 * Initializes our observability library. This must be called before everything
 * you want to be observed.
 */
export const initialize = ifEnabledCallSafely(async () => {
	const instrumentations = [
		new ErrorsInstrumentation(),
		new WebVitalsInstrumentation(),
		new SessionInstrumentation(),
		new ViewInstrumentation(),
		new ConsoleInstrumentation()
	];

	if (isTracingEnabled()) {
		// Our tracing library has a bug when imported with any code newer than ES2015.
		// While we target ES2015 in builds, the development server does not transpile.
		// ref WBP-2682
		const { TracingInstrumentation } = await import('@grafana/faro-web-tracing');
		instrumentations.push(
			new TracingInstrumentation({
				resourceAttributes: {
					[SemanticResourceAttributes.SERVICE_NAME]: APP_NAME,
					[SemanticResourceAttributes.SERVICE_VERSION]: APP_VERSION,
					[SemanticResourceAttributes.DEPLOYMENT_ENVIRONMENT]: APP_ENVIRONMENT
				},
				// Unsure of what this does, if it's working, and if it's needed.
				instrumentationOptions: {
					propagateTraceHeaderCorsUrls: CORS_TRACE_URLS
				}
			})
		);
	}

	initializeFaro({
		url: FARO_COLLECTOR_URL,
		app: {
			name: APP_NAME,
			version: APP_VERSION,
			environment: APP_ENVIRONMENT
		},
		instrumentations
	});
});

/**
 * Tracing is only enabled if we are not running the development server. Our
 * tracing dependency can only run properly absent of any code that contains
 * promises. In non-development environments like staging and production, our
 * code is transpiled to support older browsers and lacks any promises.
 */
export const isTracingEnabled = ifEnabledCallSafely(() => {
	const isViteDevelopmentServerRunning = import.meta.env.MODE === 'development';
	return !isViteDevelopmentServerRunning;
});

export const setUser = ifEnabledCallSafely(
	/** @type {(userId: string) => void} */
	(userId) => {
		faro.api.setUser({ id: userId });
		faro.api.pushEvent('authenticate');
	}
);

export const resetUser = ifEnabledCallSafely(() => {
	faro.api.pushEvent('logout');
	faro.api.resetUser();
});

/**
 * Tracks the view the user is looking at. The "view" doesn't necessarily
 * have to be a new page, it could also be a popup, or some step of an
 * interactive modal.
 */
export const setView = ifEnabledCallSafely(
	/** @type {(name: string) => void} */
	(name) => {
		faro.api.setView({ name });
	}
);

function waitForFaroInitializationDone() {
	if (faro.api !== undefined) {
		return Promise.resolve();
	}

	return new Promise((resolve, reject) => {
		let waitStartMs = Date.now();
		const intervalId = setInterval(() => {
			if (faro.api !== undefined) {
				clearInterval(intervalId);
				resolve();
				return;
			}

			const waitDurationMs = Date.now() - waitStartMs;
			if (waitDurationMs > 5000) {
				clearInterval(intervalId);
				reject('Faro api did not initialize.');
				return;
			}
		}, 10);
	});
}

/** @type {import('@opentelemetry/api').TraceAPI | null} */
let trace;

/** @param {string} name */
async function getTracer(name) {
	if (trace == null) {
		await waitForFaroInitializationDone();
		trace = faro.api.getOTEL().trace;
	}
	const tracer = trace.getTracer(name);
	return tracer;
}

/** @typedef {import('@opentelemetry/api').Span} Span */

export const createServiceSpan = ifEnabledCallSafely(
	/**
	 * @type {(servicePath: string, serviceMethod: string, userId?: string) => Span | null}
	 * May return null if tracing is not enabled.
	 */
	async (servicePath, serviceMethod, userId) => {
		if (!isTracingEnabled()) {
			return null;
		}
		// This is an independent span, not a nested span. Nested spans add a richer
		// observability experience, and there are several ways to create them. We
		// have chosen to use independent spans for now until we can invest more time
		// researching a nested span solution.
		//
		// You can start a new span anywhere by importing the tracer and calling
		// startSpan. You can use that span to track execution duration of methods,
		// or use it as an opportunity to add helpful attributes to the span. There
		// are a few different apis for creating spans, whichever you choose, make
		// sure it takes care of ending the span as well. Our observability
		// documentation provides more information about this.
		const tracer = await getTracer('feathersjs-services');
		const span = tracer.startSpan(`${servicePath} ${serviceMethod}`);

		span.setAttributes({
			service_path: servicePath,
			service_method: serviceMethod,
			user_id: userId
			// We could attach other helpful information depending on the service & method,
			// like the resource id for the get method.
		});

		return span;
	}
);

export const endSpan = ifEnabledCallSafely(
	/** @type {(span: Span, userId?: string) => void} */
	(span, userId) => {
		if (userId) {
			span.setAttributes({ user_id: userId });
		}
		span.end();
	}
);

export const endSpanWithError = ifEnabledCallSafely(
	/** @type {(span: Span, error: unknown, userId?: string) => void} */
	(span, error, userId) => {
		span.setStatus({ code: opentelemetry.SpanStatusCode.ERROR });
		span.recordException(error);
		if (userId) {
			span.setAttributes({ user_id: userId });
		}
		span.end();
	}
);

export const submitPerformanceStepDuration = ifEnabledCallSafely(
	/**
	 * Submits a step's duration to Grafana.
	 * @type {(stepName: string) => void}
	 */
	(stepName, duration) => {
		faro.api.pushMeasurement(
			{
				type: 'step_duration',
				values: { duration }
			},
			{ context: { step: stepName } }
		);
	}
);

export const STEPS = {
	LOGIN: 'LOGIN',
	GO_TO_DASHBOARD: 'GO_TO_DASHBOARD',
	GO_TO_PATIENTS: 'GO_TO_PATIENTS',
	GO_TO_TREATMENTS: 'GO_TO_TREATMENTS',
	GO_TO_PATIENT_OVERVIEW: 'GO_TO_PATIENT_OVERVIEW',
	GO_TO_PATIENT_TREATMENT_PLAN: 'GO_TO_PATIENT_TREATMENT_PLAN',
	GO_TO_TREATMENT_TUTORIAL: 'GO_TO_TREATMENT_TUTORIAL',
	GO_TO_NEXT_EXERCISE: 'GO_TO_NEXT_EXERCISE'
};

/**
 * Function exists so if scoped step naming needs to change in the future it can
 * be done in one spot.
 * @param {string} scope
 * @param {string} stepName
 */
export function createScopedStepName(scope, stepName) {
	return `${scope}/${stepName}`;
}

/**
 * Returns a wrapped function that will be called only if observability is
 * enabled, and will be called safely catching any errors and logging them.
 * @template {Function} T
 * @param {T} func
 * @returns {T}
 */
function ifEnabledCallSafely(func) {
	return (...args) => {
		try {
			if (env('ENABLE_OBSERVABILITY') === 'true') {
				return func(...args);
			}
		} catch (error) {
			console.error(error);
		}
	};
}

/**
 * This instrumentation is largely copied from Faro's console
 * instrumentation. The big difference is that instead of sending console.error
 * messages as "logs" to Grafana, we use Faro's error api to send them as
 * "exceptions" so they show up on Grafana's Frontend Observability Errors page.
 *
 * Faro's original console instrumentation for v1.3.6:
 * https://github.com/grafana/faro-web-sdk/blob/95ff610fa16233bbb35484b81ee65daaa5d725c0/packages/web-sdk/src/instrumentations/console/instrumentation.ts
 */
export class ConsoleInstrumentation extends BaseInstrumentation {
	name = 'instrumentation-console';
	static defaultDisabledLevels = [LogLevel.DEBUG, LogLevel.TRACE, LogLevel.LOG];

	/**
	 * @param {{ disabledLevels: LogLevel[] }} options
	 */
	constructor(options = {}) {
		super();
		this.options = options;
	}

	initialize() {
		this.logDebug('Initializing\n', this.options);

		const disabledLogLevels = this.options.disabledLevels ?? ConsoleInstrumentation.defaultDisabledLevels;
		const logLevelsToInstrument = allLogLevels.filter((level) => !disabledLogLevels.includes(level));

		// Instrument each console level by overriding it with our own function.
		logLevelsToInstrument.forEach((level) => {
			console[level] = (...args) => {
				try {
					// For the error level we send log messages to grafana as errors, as opposed to
					// just "logs", so they appear in the Frontend Observability Errors page.
					if (level === LogLevel.ERROR) {
						const consoleInput = args[0];
						// pushError() needs error objects, but input to console.error could be anything.
						const error = consoleInput instanceof Error ? consoleInput : new Error(consoleInput);
						this.api.pushError(error);
					} else {
						this.api.pushLog(args, { level });
					}
				} catch (err) {
					this.logError(err);
				} finally {
					this.unpatchedConsole[level](...args);
				}
			};
		});
	}
}
