export default {
	servicePath: 'sessions',
	modelName: 'Session',
	customEvents: {
		expired: (item, { app }) => {
			app._instance.ctx.checkAuth().then((validAuth) => {
				if (validAuth) {
					const exp = validAuth.authentication.payload.exp * 1000;
					const now = Date.now();
					setTimeout(app._instance.ctx.checkAuth, exp - now);
				}
			});
		}
	}
};
