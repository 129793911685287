// this code is pulled from Bootstrap (v4.3.1): modal.js
import { each } from 'lodash';

const CLASS_NAME_SCROLLBAR_MEASURER = 'modal-scrollbar-measure';
const SELECTOR_FIXED_CONTENT = '.fixed-top, .fixed-bottom';

const ScrollbarHelper = {
	isBodyOverflowing: false,
	scrollbarWidth: 0,
	checkScrollbar() {
		const rect = document.body.getBoundingClientRect();
		this.isBodyOverflowing = Math.round(rect.left + rect.right) < window.innerWidth;
		this.scrollbarWidth = this.getScrollbarWidth();
	},
	getScrollbarWidth() {
		const scrollDiv = document.createElement('div');
		scrollDiv.className = CLASS_NAME_SCROLLBAR_MEASURER;
		document.body.appendChild(scrollDiv);
		const scrollbarWidth = scrollDiv.getBoundingClientRect().width - scrollDiv.clientWidth;
		document.body.removeChild(scrollDiv);
		return scrollbarWidth;
	},
	setScrollbar() {
		if (this.isBodyOverflowing) {
			const fixedContent = [].slice.call(document.querySelectorAll(SELECTOR_FIXED_CONTENT));
			// Adjust fixed content padding
			each(fixedContent, (element) => {
				const actualMargin = window.getComputedStyle(element).getPropertyValue('margin-right');
				const calculatedMargin = actualMargin || 0;
				element.setAttribute('data-margin-right', parseFloat(calculatedMargin));
				element.style.marginRight = this.scrollbarWidth + 'px';
			});

			// Adjust body padding
			const actualMargin = window.getComputedStyle(document.body).getPropertyValue('margin-right');
			const calculatedMargin = actualMargin || 0;
			document.body.setAttribute('data-margin-right', parseFloat(calculatedMargin));
			document.body.style.marginRight = parseFloat(calculatedMargin) + this.scrollbarWidth + 'px';
		}
	},
	adjustDialog(element) {
		const isModalOverflowing = element.scrollHeight > document.documentElement.clientHeight;

		if (!this.isBodyOverflowing && isModalOverflowing) {
			element.style.paddingLeft = this.scrollbarWidth + 'px';
		}
		if (this.isBodyOverflowing && !isModalOverflowing) {
			element.style.paddingRight = this.scrollbarWidth + 'px';
		}
	},
	resetScrollbar() {
		// Restore fixed content padding
		const fixedContent = [].slice.call(document.querySelectorAll(SELECTOR_FIXED_CONTENT));
		each(fixedContent, (element) => {
			const margin = parseFloat(element.getAttribute('data-margin-right'));
			element.removeAttribute('data-margin-right');
			element.style.marginRight = margin ? margin + 'px' : '';
		});

		// Restore body padding
		const margin = parseFloat(document.body.getAttribute('data-margin-right'));
		document.body.removeAttribute('data-margin-right');
		document.body.style.marginRight = margin ? margin + 'px' : '';
	},
	resetDialog(element) {
		element.style.paddingLeft = '';
		element.style.paddingRight = '';
	}
};
export default ScrollbarHelper;
