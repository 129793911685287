<template>
	<div id="modal_manager_modals">
		<template v-for="modal in renderList" :key="modal.props.name">
			<Modal v-bind="modal.props">
				<template v-for="(slotComponent, slotName) in modal.slots" :key="slotName" #[slotName]>
					<component :is="slotComponent" />
				</template>
			</Modal>
		</template>
	</div>
</template>

<script>
import { markRaw } from 'vue';
import { every } from 'lodash';
import ScrollbarHelper from '../utils/scrollbar';

export default {
	name: 'ModalManager',
	inject: ['$modals', '$modalRegistry'],
	data() {
		return {
			renderList: {}
		};
	},
	created() {
		this.$modals.manager.on('create', ({ name, props }) => {
			this.renderList[name] = { props: { ...props.props, name }, slots: props.slots };
		});
		this.$modals.manager.on('destroy', ({ name }) => {
			delete this.renderList[name];
		});
		this.$modals.manager.on('cloneComponent', ({ name, component, props = {}, componentProps }) => {
			this.renderList[name] = {
				props: { ...(props.props || {}), name },
				slots: {
					...(props.slots || {}),
					default: markRaw({
						render() {
							for (const prop in componentProps) {
								if (Object.hasOwnProperty.call(componentProps, prop)) {
									component.$.vnode.props[prop] = componentProps[prop];
								}
							}
							return component.$.vnode;
						}
					})
				}
			};
		});

		this.$modals.manager.on('show', ({ name }) => {
			const modal = this.$modalRegistry.get(name);
			if (modal) {
				modal.show();
				if (!document.body.classList.contains('modal-open')) {
					ScrollbarHelper.checkScrollbar();
					ScrollbarHelper.setScrollbar();
				}

				document.body.classList.add('modal-open');
			}
		});
		this.$modals.manager.on('hide', ({ name }) => {
			const modal = this.$modalRegistry.get(name);
			if (modal) modal.hide();
		});
		this.$modals.manager.on('reset', () => {
			if (this.noModalsVisible()) this.resetBody();
		});
	},
	methods: {
		noModalsVisible() {
			return every(this.$modalRegistry.get(), { isVisible: false });
		},
		resetBody() {
			ScrollbarHelper.resetScrollbar();
			document.body.classList.remove('modal-open');
		}
	}
};
</script>

<style></style>
