/**
 * Root/global app error handler. Will catch all errors. Use this error handler
 * to log errors and make them appear in Grafana observability. Will ignore 401
 * & 403 errors.
 */
export const errorHandler = (error) => {
	const errorCode = error?.code ?? null;

	const skipLogging = errorCode === 401 || errorCode === 403;
	if (skipLogging) {
		return;
	}

	// Current console instrumentation will send anything logged to console.error()
	// as an exception to Grafana.
	console.error(error);
};
