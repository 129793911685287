<template>
	<BaseModal
		name="overduePayment"
		modal-class="overdue-payment-alert"
		size="lg"
		:is-dismissible="false"
		:show-on-load="shouldNotify()"
		@hidden="handleHidden"
	>
		<Icon icon="warning-filled-circle" />
		<Heading class="mb-4" :level="4">Your membership payment is overdue</Heading>
		<p>
			You have {{ daysBeforeDeactivation }} days to complete payment<br />
			or your membership will be deactivated.
		</p>
		<div class="btn-row">
			<BaseButton color="default" size="lg" @click="handleCancel"
				><Icon icon="calendar" />Remind me tomorrow</BaseButton
			>
			<FastspringAccountLink @click="handleContinue" />
		</div>
	</BaseModal>
</template>

<script>
import { getServiceStore } from '@/plugins/FeathersAPI';
import moment from 'moment';
import BaseModal from '@/components/modals/BaseModal.vue';
import BaseButton from '@/components/buttons/BaseButton.vue';
import Icon from '@/components/Icon.vue';
import Heading from '../Heading.vue';
import FastspringAccountLink from '@/components/FastspringAccountLink.vue';

export default {
	name: 'OverduePaymentAlert',
	components: {
		BaseModal,
		BaseButton,
		Icon,
		Heading,
		FastspringAccountLink
	},
	inject: ['$modals'],
	emits: ['cancel', 'continue'],
	computed: {
		overdueLicense() {
			return getServiceStore('auth').user.activeLicenses.find((license) => license.state == 'overdue') || {};
		},
		daysBeforeDeactivation() {
			return moment(this.overdueLicense.expireDate).diff(moment(), 'days');
		}
	},
	watch: {
		'$route.path'() {
			this.shouldNotify();
		}
	},
	methods: {
		shouldNotify() {
			const notifiedAt = window.localStorage.getItem('notifiedOfOverduePayment');
			if (typeof notifiedAt == 'undefined' || !moment(notifiedAt).isSame(moment(), 'day'))
				this.$modals.show('overduePayment');
		},
		handleCancel() {
			this.$modals.hide('overduePayment');
			this.$emit('cancel');
		},
		handleContinue() {
			this.$modals.hide('overduePayment');
			this.$emit('continue');
		},
		handleHidden() {
			window.localStorage.setItem('notifiedOfOverduePayment', moment().format());
		}
	}
};
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/functions.scss';

.overdue-payment-alert {
	text-align: center;

	.icon-warning-filled-circle {
		font-size: rem(56);
		margin-bottom: rem(16);
		color: $warning;
	}
	.btn-row {
		display: flex;
		justify-content: center;
		gap: 1rem;
		margin-top: rem(32);
		margin-bottom: rem(-8);
	}
}
</style>
